import { Text, View, Platform } from "react-native";
import WebView from "react-native-webview";

const Survey = () => {
  return (
    <View style={{ flex: 1 }}>
      {/* {Platform.OS === "web" && (
        <iframe
          src={"https://forms.gle/YTqGip7jryozhyZr9"}
          style={{
            border: 0,
            height: "100%",
          }}
        />
      )} */}
      {Platform.OS === "web" ? (
        <iframe
          src={"https://forms.gle/YTqGip7jryozhyZr9"}
          style={{
            border: 0,
            height: "100%",
          }}
        />
      ) : (
        <WebView
          source={{
            uri: "https://docs.google.com/forms/d/e/1FAIpQLSf6tBXaLoMDG6ThgG4Iwfm7yrlcXuDeWm3hThwbSZKzxRQMIQ/viewform",
            // html: '<iframe width="100%" height="50%" src="https://docs.google.com/forms/d/e/1FAIpQLSf6tBXaLoMDG6ThgG4Iwfm7yrlcXuDeWm3hThwbSZKzxRQMIQ/viewform" frameborder="0" allowfullscreen></iframe>',
          }}
          style={{
            flex: 1,
            height: "100%",
            //backgroundColor: 'red'
          }}
        />
      )}
    </View>
  );
};

const styles = {};

export default Survey;
