import {View} from "react-native";
import {theme} from "../helper";
import React from "react";

const StepsBar = ({count, step}) => {
  const arr = []
  count = 7

  for (let i = 0; i < count; i++) {
    const index = (i + 1);
    const current = index > step

    arr.push(
      <View
        key={`step-${i}`}
        style={{
          flex: 1,
          backgroundColor: !current ? theme.primary : 'white',
          height: 5,
          borderRadius: 5,
          marginRight: index === count ? 0 : 5
        }}
      />
    )
  }

  return (
    <View style={{flexDirection: 'row'}}>
      {arr}
    </View>
  )
}

export default StepsBar