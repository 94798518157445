import React, { useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Dimensions,
  Platform,
  Linking,
  NativeModules,
  Button,
  Alert,
} from "react-native";
import validator from "validator";
import { Entypo, Feather, MaterialIcons } from "@expo/vector-icons";
import {api, redux, theme} from "../helper";
import _ from "lodash";
import globalStyles from "../../styles/global.css";
import QRCode from "react-native-qrcode-svg";
import { useRoute } from "@react-navigation/native";
import qs from 'qs'

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;


function QRCodeScreen(props) {
  const { navigation, setLoading, setDetails, loading } = props;

  const [search, setSearch] = useState("");
  const inputRef = React.useRef();


  useEffect(() => {
    //setLoading(true)
  }, [])

  const goResult = () => {
    const split = search.split('?')
    const {_resultId} = qs.parse(split[1])

    if(_resultId) {
      setLoading(true)
      api(`results/${_resultId}`).then(res => {
        const {conditions, details} = res.result.result
        setDetails(details)
        navigation.replace("conditions", conditions)
      }).catch(e => {
        alert(e.message)
      }).finally(() => {
        setLoading(false)
      })
    } else {
      setSearch('')
      inputRef.current.focus()
    }
  }

  if(loading) {
    return (
      <View
        style={{
          height: 200,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Text style={{fontSize: 30}}>
          Loading...
        </Text>
      </View>
    )
  }

  return (
    <ScrollView
      style={{
        flex: 1,
        padding: 20,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <View
          style={{
            height: Platform.OS === "web" ? 40 : componentHeight * 0.096,
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#e8e8e8",
            borderRadius: 20,
            paddingHorizontal:
              Platform.OS === "web" ? 20 : componentHeight * 0.048,
          }}
        >
          <TextInput
            placeholder={`Please scan your QR Code using scanner below`}
            style={[
              globalStyles.fontSM,
              globalStyles.font_FamilyRegular,
              {
                flex: 1,
                height: Platform.OS === "web" ? 40 : componentHeight * 0.096,
              },
            ]}
            showSoftInputOnFocus={false}
            value={search}
            onChangeText={(text) => setSearch(text)}
            ref={inputRef}
            onLayout={() => inputRef.current.focus()}
            onEndEditing={() => goResult()}
          />
          {/*<TouchableOpacity
            // onPress={openUrl}
            onPress={async () => {

            }}
          >
            <Feather
              name="Search"
              size={Platform.OS === "web" ? 20 : componentWidth * 0.08}
              color="black"
            />
          </TouchableOpacity>*/}
        </View>

      </View>
    </ScrollView>
  );
}

export default redux(
  ["details", "language", 'loading'],
  ['setLoading', 'setDetails']
)(QRCodeScreen);