import React, { useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Platform,
  Dimensions,
} from "react-native";
import { api, redux } from "../helper";
import { Slider, Switch } from "@rneui/themed";
import _ from "lodash";
import Button from "../Components/Button";
import { FontAwesome, Fontisto } from "@expo/vector-icons";
import globalStyles from "../../styles/global.css";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function TriageScreen(props) {
  const { route, navigation, Translate, details } = props;

  const {condition, resultId} = route.params;
  let { color, description } = _.get(condition, "triage", {});

  if(parseFloat(details?.bloodPressure?.systolic) >= 180 || parseFloat(details?.bloodPressure?.diastolic) >= 110) {
    color = 'red'
  } else if(parseFloat(details?.bloodPressure?.systolic) <= 80 || parseFloat(details?.bloodPressure?.diastolic) <= 50) {
    color = 'red'
  }
  if(parseFloat(details?.pulseRate) >= 120) {
    color = 'red'
  } else if(parseFloat(details?.pulseRate) <= 50) {
    color = 'red'
  }
  if(parseFloat(details?.temperature) >= 40) {
    color = 'red'
  }
  if(parseFloat(details?.pulseOximetry) <= 92) {
    color = 'red'
  }


  const getTriageTitle = (triageColor) => {
    if (triageColor === "green") {
      return Translate.getStatic("triage_green_title");
    } else if (triageColor === "lime") {
      return Translate.getStatic("triage_lime_title");
    } else if (triageColor === "yellow") {
      return Translate.getStatic("triage_yellow_title");
    } else if (triageColor === "orange") {
      return Translate.getStatic("triage_orange_title");
    } else if (triageColor === "red") {
      return Translate.getStatic("triage_red_title");
    } else {
      return "None";
    }
  };

  const getTriageDescription = (triageColor) => {
    if (triageColor === "green") {
      return Translate.getStatic("triage_green_message");
    } else if (triageColor === "lime") {
      return Translate.getStatic("triage_lime_message");
    } else if (triageColor === "yellow") {
      return Translate.getStatic("triage_yellow_message");
    } else if (triageColor === "orange") {
      return Translate.getStatic("triage_orange_message");
    } else if (triageColor === "red") {
      return Translate.getStatic("triage_red_message");
    } else {
      return "None";
    }
  };

  const getHippo = (triageColor) => {
    if (triageColor === "green" || triageColor === 'lime') {
      return require("../../assets/happy.png");
    } else if (triageColor === "yellow" || triageColor === 'orange') {
      return require("../../assets/serious.png");
    } else if (triageColor === "red") {
      return require("../../assets/sad.png");
    }
  };

  return (
    <View style={{ flex: 1, padding: 20 }}>
      <View style={{ flex: 1 }}>
        <View style={{ alignItems: "center" }}>
          <Image
            style={{
              height: 150,
              width: 150,
            }}
            source={getHippo(color)}
          />
        </View>
        <View
          style={{
            height: Platform.OS === "web" ? 300 : componentHeight * 0.6,
            width: "100%",
            backgroundColor: color,
            borderRadius: 20,
            padding: 15,
          }}>
          <View
            style={{
              padding: 15,
              borderRadius: 15,
              backgroundColor: "white",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <View style={{ flex: 1 }}>
              <Text
                style={[globalStyles.fontXL, globalStyles.font_FamilySemiBold]}>
                {getTriageTitle(color)}
              </Text>
            </View>
            {color === "green" && (
              <FontAwesome
                style={{ marginHorizontal: 5 }}
                name="home"
                size={50}
                color="black"
              />
            )}
            {color === "yellow" && (
              <Fontisto
                style={{ marginHorizontal: 5 }}
                name="doctor"
                size={50}
                color="black"
              />
            )}
            {color === "red" && (
              <FontAwesome
                style={{ marginHorizontal: 5 }}
                name="ambulance"
                size={50}
                color="black"
              />
            )}
          </View>
          <View
            style={{
              padding: 15,
              borderRadius: 15,
              backgroundColor: "white",
              marginTop: 15,
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Text
              style={[
                globalStyles.fontSM,
                globalStyles.font_FamilyRegular,
                {
                  textAlign: "center",
                },
              ]}>
              {getTriageDescription(color)}
            </Text>
          </View>
        </View>
      </View>
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          style={{ marginTop: 10 }}
          onPress={() => {
            navigation.replace("triage_description", {
              condition,
              resultId
            });
          }}
        >
          {Translate.getStatic("continue")}
        </Button>
      </View>
    </View>
  );
}

export default redux(['details'], [])(TriageScreen);
