import {Text, View, Platform, ImageBackground, Image, TouchableOpacity, ScrollView, TextInput} from "react-native";
import globalStyles from "../../styles/global.css";
import {api, redux, theme} from "../helper";
import {FontAwesome, AntDesign} from '@expo/vector-icons';
import Button from "../Components/Button";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import SelectionModal from "../Components/SelectionModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

const countryList = require('country-list');

const genderOptions = [
  {name: "Male", value: "male"},
  {name: "Female", value: "female"},
]

const countriesOptions = _.map(countryList.getNames(), name => ({name, value: name}))

const Profile = ({user, Translate, setLoading, setCurrentUser}) => {

  const [genderModal, setGenderModal] = useState()
  const [countriesModal, setCountriesModal] = useState()
  const [editData, setEditData] = useState(user)

  useEffect(() => {
  },[])

  const onChange = (path, value) => {
    const clone = _.cloneDeep(editData)
    _.set(clone, path, value)
    setEditData(clone)
  }

  const save = async () => {
    setLoading(true)
    try {
      const profile = await api('users/profile', 'put', editData)
        .then(res => res.result)
      setCurrentUser(profile)
    } catch (e) {
      alert(e.message)
    } finally {
      setLoading(false)
    }
  }

  const onSelectImage = async () => {
    try {
      //setLoading(true)

      const image = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      });

      const result = await ImageManipulator.manipulateAsync(image.uri,
        [{ resize: { width: 500 }}],
        {
          format: ImageManipulator.SaveFormat.JPEG,
          base64: true
        }
      )

      onChange('photoUrl', `data:image/jpg;base64,${result.base64}`)

    } catch (e) {
      alert(e.message)
    } finally {
      //setLoading(false)
    }
  }

  const photoUrl = _.get(editData, 'photoUrl')

  return (
    <ScrollView style={{flex: 1,}}>
      <SelectionModal
        title={"Gender"}
        modal={genderModal}
        Translate={Translate}
        setModal={setGenderModal}
        options={genderOptions}
        onSelected={(item) => {
          onChange('gender', item.value)
        }}
        selection={false}
      />
      <SelectionModal
        title={"Countries"}
        modal={countriesModal}
        Translate={Translate}
        setModal={setCountriesModal}
        options={countriesOptions}
        onSelected={(item) => {
          console.log(item)
          onChange('country', item.value)
        }}
        selection={false}
      />
      <ImageBackground
        style={{
          flex: 1,
          position: 'relative',
          alignItems: 'center'
        }}
        imageStyle={{
          resizeMode: 'cover',
          height: 160
        }}
        source={require('../../assets/profile-bg.png')}
      >
        <TouchableOpacity
          onPress={onSelectImage}
          style={{
            alignItems: 'center'
          }}
        >
          <ImageBackground
            source={photoUrl ? {uri: photoUrl} : require('../../assets/default-profile.jpg')}
            resizeMode={'cover'}
            style={{
              width: 100,
              height: 100,
              borderRadius: 50,
              overflow: 'hidden',
              margin: 15,
              backgroundColor: 'white',
              alignItems: 'flex-end',
              justifyContent: 'flex-end'
            }}
          >
            {/*<View
              style={{
                backgroundColor: 'white',
                borderRadius: 30,
                height: 30,
                width: 30,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FontAwesome name="edit" size={20} color="black" style={{marginTop: 3, marginLeft: 3}}/>
            </View>*/}
          </ImageBackground>
        </TouchableOpacity>

        <View
          style={{
            backgroundColor: 'white',
            marginHorizontal: 20,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: '#eee',
          }}
        >
          <TextRow
            editData={editData}
            onChange={onChange}
            title={'Name'}
            field={'displayName'}
            type={'text'}
            placeholder={'Wee Chong Wei'}
          />
          {/*<TextRow
            editData={editData}
            onChange={onChange}
            title={'Email'}
            field={'email'}
            type={'text'}
            placeholder={'test@example.com'}
          />*/}
          <TextRow
            editData={editData}
            onChange={onChange}
            title={'Phone Number'}
            field={'phoneNumber'}
            type={'text'}
            placeholder={'+60168527507'}
          />
          <TextRow
            editData={editData}
            title={'Gender'}
            field={'gender'}
            type={'select'}
            options={genderOptions}
            onPress={() => {
              setGenderModal({
                title: 'Gender'
              })
            }}
          />
          <TextRow
            editData={editData}
            onChange={onChange}
            title={'Date of Birth'}
            field={'dob'}
            type={'text'}
            placeholder={'DD/MM/YYYY'}
          />
          <TextRow
            editData={editData}
            onChange={onChange}
            title={'Country'}
            field={'country'}
            type={'select'}
            options={countriesOptions}
            onPress={() => {
              setCountriesModal({
                title: 'Countries'
              })
            }}
          />
        </View>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            margin: 15
          }}
        >
          <Button
            onPress={save}
          >
            Save
          </Button>
        </View>
      </ImageBackground>
    </ScrollView>
  );
};

const TextRow = ({editData, onChange, field, title, type, placeholder, onPress, options}) => {
  return (
    <View
      style={{
        height: 60,
        borderBottomWidth: 1,
        borderColor: '#eee',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20
      }}
    >
      <View
        style={{width: 100}}
      >
        <Text
          style={[
            globalStyles.font_FamilyMedium
          ]}
        >
          {title}
        </Text>
      </View>
      <View style={{flex: 1}}>
        {type === 'text' &&
          <TextInput
            placeholder={placeholder}
            placeholderTextColor={"#c5c5c5"}
            style={[
              globalStyles.font_FamilySemiBold,
              styles.inputStyle
            ]}
            value={_.get(editData, field, "")}
            onChangeText={(text) => {
              onChange(field, text)
            }}
          />
        }
        {type === 'select' &&
          <TouchableOpacity
            style={[
              styles.inputStyle,
              {}
            ]}
            onPress={onPress}
          >
            <View
              style={{
                flex: 1
              }}
            >
              <Text
                style={[
                  globalStyles.font_FamilySemiBold,
                ]}
              >
                {
                  _.find(options, {value: _.get(editData, field)})?.name
                  ||
                  <Text style={{color: '#c5c5c5'}}>Please Select...</Text>
                }
              </Text>
            </View>
            <AntDesign name="caretdown" size={16} color={'black'} style={{marginBottom: 3}} />
          </TouchableOpacity>
        }
      </View>
    </View>
  )
}

const styles = {
  inputStyle: {
    height: 40,
    backgroundColor: '#eee',
    paddingHorizontal: 10,
    borderRadius: 10,
    alignItems: 'center',
    flexDirection: 'row'
  }
};

export default redux(['user'], ['setLoading', 'setCurrentUser'])(Profile);
