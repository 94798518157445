import React, { useEffect, useState } from "react";
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Platform,
  Dimensions,
} from "react-native";
import { api, redux } from "../helper";
import { Slider, Switch } from "@rneui/themed";
import _ from "lodash";
//import Markdown from 'react-native-markdown-renderer';
import Markdown from "react-native-markdown-display";
import { FontAwesome } from "@expo/vector-icons";
import Button from "../Components/Button";
import globalStyles from "../../styles/global.css";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function RedFlagScreen(props) {
  const { route, navigation, Translate } = props;

  const { symptom, condition, resultId } = route.params;

  Translate.load(symptom);

  return (
    <View style={{ flex: 1, padding: 20 }}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text
          style={[
            globalStyles.fontXL,
            globalStyles.font_FamilyRegular,
            {
              marginRight: 10,
            },
          ]}>
          {Translate.get("name")}
          {Translate.getStatic("red_flag_title_suffix")}
        </Text>
        <FontAwesome
          name="flag"
          size={Platform.OS === "web" ? 24 : componentWidth * 0.1}
          color="red"
        />
      </View>
      <ScrollView
        style={{
          marginTop: 20,
        }}
        contentContainerStyle={{
          backgroundColor: "white",
          height: "100%",
          borderRadius: 10,
          //paddingTop: 20,
          padding: 20,
          //marginBottom: 20,
          //paddingVertical: 20
        }}>
        <Markdown
          style={{
            body: globalStyles.fontSM,
          }}>
          {Translate.get("redFlagDescription") || "Not available yet"}
        </Markdown>
      </ScrollView>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
        }}>
        <Button
          style={{ flex: 1, marginRight: 5, backgroundColor: "red" }}
          textColor={"white"}
          onPress={() => {
            const clone = _.cloneDeep(condition);
            _.set(clone, "triage.color", "red");
            navigation.replace("triage", {
              condition: clone,
              resultId
            });
          }}>
          {Translate.getStatic("yes")}
        </Button>
        <Button
          style={{ flex: 1, marginLeft: 5, backgroundColor: "green" }}
          textColor={"white"}
          onPress={() => {
            navigation.replace("triage", {
              condition,
              resultId
            });
          }}>
          {Translate.getStatic("no")}
        </Button>
      </View>
    </View>
  );
}

export default redux([], [])(RedFlagScreen);
