import { Text, View, Platform } from "react-native";
import WebView from "react-native-webview";
import TNC from "../Components/TNC";

const Terms = () => {
  return (
    <View style={{ flex: 1, backgroundColor: 'white', padding: 10 }}>
      <TNC />
    </View>
  );
};

const styles = {};

export default Terms;
