import React, { useEffect, useState } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  Modal,
  TextInput,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import { api, redux, theme } from "../helper";
import { Slider, Switch, CheckBox } from "@rneui/themed";
import _ from "lodash";
import { MaterialIcons, Entypo } from "@expo/vector-icons";
import SelectionModal from "../Components/SelectionModal";
import StepsBar from "../Components/StepsBar";
import Button from "../Components/Button";
import globalStyles from "../../styles/global.css";

const windowDimensions = Dimensions.get("window");

const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function KnownIllnessScreen(props) {
  const { details, mergeDetails, navigation, route, Translate } = props;

  const [modal, setModal] = useState(false);
  const [knownIllnesses, setKnownIllnesses] = useState([]);

  const isKnownIllnesses = route?.name === "known_illnesses";

  useEffect(() => {
    api("known-illnesses").then((res) => {
      setKnownIllnesses(res.result);
    });
  }, []);

  return (
    <View style={{ flex: 1, padding: 20 }}>
      <SelectionModal
        modal={modal}
        setModal={setModal}
        Translate={Translate}
        options={knownIllnesses}
        onSelected={(illness, path) => {
          mergeDetails(`${path}.${illness._id}`, illness);
        }}
      />
      <StepsBar step={isKnownIllnesses ? 2 : 3} />
      {isKnownIllnesses && (
        <View style={{ flex: 1 }}>
          <Text
            style={[
              globalStyles.fontLG,
              globalStyles.font_FamilyBold,
              {
                marginTop: 20,
                color: theme.secondary,
                textAlign: "center",
              },
            ]}>
            {Translate.getStatic("underlying_conditions_questions")}
          </Text>
          <Text
            style={[
              globalStyles.fontXS,
              globalStyles.font_FamilyLight,
              {
                color: "red",
                textAlign: "center",
                marginTop: 5,
              },
            ]}>
            {Translate.getStatic("please_add")}
          </Text>
          <ListIllnesses
            path={"knownIllnesses"}
            details={details}
            mergeDetails={mergeDetails}
            Translate={Translate}
            onPress={() => {
              setModal({
                title: Translate.getStatic("underlying_conditions"),
                path: "knownIllnesses",
                filter: {
                  type: "standard",
                },
              });
            }}
          />
        </View>
      )}
      {!isKnownIllnesses && (
        <View style={{ flex: 1 }}>
          <Text
            style={[
              globalStyles.fontLG,
              globalStyles.font_FamilyBold,
              {
                color: theme.secondary,
                textAlign: "center",
                marginTop: 20,
              },
            ]}>
            {Translate.getStatic("family_conditions_questions")}
          </Text>
          <Text
            style={[
              globalStyles.fontXS,
              globalStyles.font_FamilyLight,
              {
                color: "red",
                textAlign: "center",
                marginTop: 5,
              },
            ]}>
            {Translate.getStatic("please_add")}
          </Text>
          <ListIllnesses
            path={"familyIllnesses"}
            details={details}
            mergeDetails={mergeDetails}
            Translate={Translate}
            onPress={() => {
              setModal({
                title: Translate.getStatic("family_conditions"),
                path: "familyIllnesses",
                filter: {
                  type: "familyHistory",
                },
              });
            }}
          />
        </View>
      )}
      <View
        style={{ alignItems: "center", justifyContent: "center", height: 150 }}>
        <Button
          style={{ marginTop: 10 }}
          onPress={() => {
            if (isKnownIllnesses) {
              navigation.push("family_illnesses");
            } else {
              navigation.push("symptoms");
            }
          }}>
          Continue
        </Button>
      </View>
    </View>
  );
}

const ListIllnesses = ({ path, details, onPress, mergeDetails, Translate }) => {
  return (
    <View
      style={{
        paddingVertical: 10,
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
      }}>
      {_.map(_.get(details, path), (illness) => {
        return (
          <TouchableOpacity
            key={illness._id}
            onPress={() => {
              mergeDetails(`${path}.${illness._id}`, null);
            }}
            style={{
              backgroundColor: theme.tertiary,
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginTop: 5,
              marginRight: 5,
              padding: Platform.OS === "web" ? 5 : componentHeight * 0.012,
              borderRadius: 20,
              maxWidth: Platform.OS === "web" ? 300 : componentHeight * 0.75,
              height: Platform.OS === "web" ? 35 : componentHeight * 0.08,
            }}>
            <Text
              style={[
                globalStyles.fontSM,
                globalStyles.font_FamilyRegular,
                {
                  color: "white",
                  marginLeft: 5,
                  marginRight: 5,
                  top: 3,
                },
              ]}>
              {illness.name}
            </Text>
            <Entypo
              name="cross"
              size={Platform.OS === "web" ? 24 : componentWidth * 0.1}
              color="white"
            />
          </TouchableOpacity>
        );
      })}
      <TouchableOpacity
        onPress={() => onPress()}
        style={{
          backgroundColor: theme.primary,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 20,
          marginTop: 5,
          padding: Platform.OS === "web" ? 5 : componentHeight * 0.012,
          paddingRight: 10,
          height: Platform.OS === "web" ? 35 : componentHeight * 0.08,
        }}>
        <MaterialIcons
          name="add-circle"
          size={Platform.OS === "web" ? 24 : componentWidth * 0.1}
          color="white"
        />
        <Text
          style={[
            globalStyles.fontSM,
            globalStyles.font_FamilyBold,
            {
              top: 3,
              marginLeft: 5,
              color: "white",
            },
          ]}>
          {Translate.getStatic("add")}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default redux(["details"], ["mergeDetails"])(KnownIllnessScreen);
