import React, { useEffect, useState } from "react";
import { Text, View, Dimensions, Platform } from "react-native";
import { redux, theme } from "../helper";
import { Slider, Switch } from "@rneui/themed";
import _ from "lodash";
import StepsBar from "../Components/StepsBar";
import Button from "../Components/Button";
import { Entypo } from "@expo/vector-icons";
import globalStyles from "../../styles/global.css";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function DurationSeverity(props) {
  const { details, navigation, mergeDetails, route, Translate } = props;

  const [severity, setSeverity] = useState("");
  const durations = {
    a: Translate.getStatic("less_than_48hr"),
    b: Translate.getStatic("48hr_to_7days"),
    c: Translate.getStatic("more_than_7days"),
  };
  const severities = {
    a: Translate.getStatic("getting_better"),
    b: Translate.getStatic("remains_the_same"),
    c: Translate.getStatic("worsening"),
  };

  const isDuration = route?.name === "duration";
  const isSeverity = route?.name === "severity";
  const isPainScore = route?.name === "pain";

  const painScore = _.get(details, 'painScore', 0)

  const goNext = () => {
    if (isPainScore) {
      if (painScore === 10) {
        mergeDetails("severity", "c");
      } else if (painScore >= 5 && painScore <= 9) {
        mergeDetails("severity", "b");
      }
      if (painScore <= 4) {
        mergeDetails("severity", "a");
      }
    }
    if (isDuration) {
      if (details?.symptom?.severityType === "painScore") {
        navigation.push("pain");
      } else {
        navigation.push("severity");
      }
    } else {
      navigation.push("presentations");
    }
  };

  let thumbColor = "green";

  if (painScore >= 8) {
    thumbColor = "red";
  } else if (painScore >= 3) {
    thumbColor = "goldenrod";
  }

  return (
    <View style={{ flex: 1, padding: 20 }}>
      <StepsBar step={isDuration ? 5 : 6} />
      {(isDuration || isSeverity) && (
        <View
          style={{
            marginTop: 20,
            alignItems: "center",
            flex: 1,
          }}
        >
          <Text
            style={[
              globalStyles.fontLG,
              globalStyles.font_FamilyBold,
              {
                color: theme.secondary,
                textAlign: "center",
              },
            ]}
          >
            {isDuration
              ? Translate.getStatic("duration_questions")
              : Translate.getStatic("severity_questions")}
          </Text>
          <View style={{ flexDirection: "column" }}>
            {_.map(isDuration ? durations : severities, (label, key) => {
              return (
                <Button
                  key={key}
                  style={{
                    fontSize:
                      Platform.OS === "web" ? 14 : componentWidth * 0.05,
                    width:
                      Platform.OS === "web" ? 250 : componentHeight * 0.625,
                    height:
                      Platform.OS === "web" ? 50 : componentHeight * 0.125,
                    marginTop: 15,
                  }}
                  type={
                    _.get(details, isDuration ? "duration" : "severity") === key
                      ? null
                      : "secondary"
                  }
                  onPress={() => {
                    mergeDetails(isDuration ? "duration" : "severity", key);
                    // goNext();
                    setSeverity(label);
                  }}
                  size={"small"}
                >
                  {label}
                </Button>
              );
            })}
          </View>
        </View>
      )}

      {isPainScore && (
        <View
          style={{
            marginTop: 20,
            alignItems: "center",
            flex: 1,
          }}
        >
          <Text
            style={[
              globalStyles.fontLG,
              globalStyles.font_FamilyBold,
              {
                color: theme.secondary,
                textAlign: "center",
              },
            ]}
          >
            {Translate.getStatic("pain_questions")}
          </Text>
          <View
            style={{
              flexDirection: "column",
              marginTop: 10,
              maxWidth: Platform.OS === "web" ? 300 : componentHeight * 0.75,
            }}
          >
            <View
              style={{
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <View style={{ alignItems: "flex-start", flex: 1 }}>
                <Entypo
                  name="emoji-happy"
                  size={Platform.OS === "web" ? 40 : componentWidth * 0.16}
                  color={theme?.secondary}
                />
                <Text
                  style={[
                    globalStyles.fontXS,
                    globalStyles.font_FamilyBold,
                    {
                      color: theme.secondary,
                    },
                  ]}
                >
                  {Translate.getStatic("painless")}
                </Text>
              </View>
              <View style={{ alignItems: "center", flex: 1 }}>
                <Entypo
                  name="emoji-neutral"
                  size={Platform.OS === "web" ? 40 : componentWidth * 0.16}
                  color={theme?.secondary}
                />
                <Text
                  style={[
                    globalStyles.fontXS,
                    globalStyles.font_FamilyBold,
                    {
                      color: theme.secondary,
                    },
                  ]}
                >
                  {Translate.getStatic("moderate")}
                </Text>
              </View>
              <View style={{ alignItems: "flex-end", flex: 1 }}>
                <Entypo
                  name="emoji-sad"
                  size={Platform.OS === "web" ? 40 : componentWidth * 0.16}
                  color={theme?.secondary}
                />
                <Text
                  style={[
                    globalStyles.fontXS,
                    globalStyles.font_FamilyBold,
                    {
                      color: theme.secondary,
                    },
                  ]}
                >
                  {Translate.getStatic("unbearable")}
                </Text>
              </View>
            </View>
            <Slider
              value={painScore}
              onValueChange={(painScore) => mergeDetails('painScore',painScore)}
              maximumValue={10}
              minimumValue={0}
              step={1}
              allowTouchTrack
              minimumTrackTintColor={thumbColor}
              trackStyle={{
                height: Platform.OS === "web" ? 5 : componentHeight * 0.012,
              }}
              thumbStyle={{
                height: Platform.OS === "web" ? 30 : componentHeight * 0.065,
                width: Platform.OS === "web" ? 30 : componentHeight * 0.065,
                backgroundColor: thumbColor,
              }}
              style={{
                width: Platform.OS === "web" ? 300 : componentHeight * 0.75,
                marginTop: 10,
              }}
              thumbProps={{
                children: (
                  <View
                    style={{
                      width:
                        Platform.OS === "web" ? 30 : componentHeight * 0.065,
                      height:
                        Platform.OS === "web" ? 30 : componentHeight * 0.065,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={[
                        globalStyles.fontLG,
                        globalStyles.font_FamilyBold,
                        {
                          color: "white",
                        },
                      ]}
                    >
                      {painScore}
                    </Text>
                  </View>
                ),
              }}
            />
            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
                marginHorizontal: 8,
                marginTop: Platform.OS === "web" ? 0 : componentHeight * 0.012,
              }}
            >
              <Text
                style={[
                  globalStyles.fontSM,
                  globalStyles.font_FamilyBold,
                  {
                    color: theme.secondary,
                  },
                ]}
              >
                0
              </Text>
              <Text
                style={[
                  globalStyles.fontSM,
                  globalStyles.font_FamilyBold,
                  {
                    color: theme.secondary,
                  },
                ]}
              >
                10
              </Text>
            </View>
          </View>
        </View>
      )}
      <View
        style={{ alignItems: "center", justifyContent: "center", height: 150 }}
      >
        <Text>{JSON.stringify(mergeDetails)}</Text>
        <Button
          style={{ marginTop: 10 }}
          disabled={!painScore && !severity}
          onPress={() => {
            goNext();
          }}
        >
          {Translate.getStatic("continue")}
        </Button>
      </View>
    </View>
  );
}

export default redux(["details"], ["mergeDetails"])(DurationSeverity);
