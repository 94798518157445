import _ from 'lodash';

const initialState = {
	user: null,
	dialog: false,
	loading: false,
	/*details: {
    "gender": "male",
    "age": 54,
    "symptom": {
      "_id": "631949a83483119ae49c206b",
      "name": "Pain In Calf",
      "area": "lowerLimb",
      "gender": "unisex",
      "severityType": "painScore",
      "createdAt": "2022-09-08T01:47:20.399Z",
      "updatedAt": "2023-05-06T08:58:11.197Z",
      "__v": 0,
      "redFlagDescription": "*Please select 'yes' if you are experiencing any of these symptoms below :*\n\n1.\tRedness and swelling in the calf.\n2.\tLoss of movement or sensation, colour changes, or warmth of the affected toe(s).\n3.\tCold, pale toe(s).\n4.\tShortness of breath.\n\n",
      "translations": {
        "ms": {
          "redFlagDescription": "*Sila pilih 'ya' jika anda mengalami sebarang gejala di bawah:*\n\n1. Kemerahan dan pembengkakan pada betis.\n2. Kehilangan gerakan atau sensasi, perubahan warna, atau suhu pada jari kaki.\n3. Jari kaki yang sejuk dan pucat.\n4. kesesakan nafas\n",
          "name": "Sakit Betis"
        }
      }
    },
    "duration": "c",
    "painScore": 5,
    "severity": "b",
    "presentations": {
      "630498c3508551080e9c866d": {
        "_id": "630498c3508551080e9c866d",
        "name": "Bilateral Knee Pain",
        "createdAt": "2022-08-23T09:07:15.026Z",
        "updatedAt": "2022-12-28T12:24:55.650Z",
        "__v": 0,
        "question": "Do you have pain of both knees?",
        "translations": {
          "ms": {
            "question": "Adakah anda mengalami kesakitan pada kedua-dua lutut?"
          }
        },
        "specific": true,
        "index": 0,
        "frequency": 0,
        "required": true
      },
      "63031f5607871381fb1b44a4": {
        "_id": "63031f5607871381fb1b44a4",
        "name": "Activity Related Pain",
        "createdAt": "2022-08-22T06:16:54.476Z",
        "updatedAt": "2022-12-28T12:02:10.535Z",
        "__v": 0,
        "question": "Do you have pain that worsens with activity?",
        "translations": {
          "ms": {
            "name": "Sakit Berkaitan Aktiviti",
            "question": "Adakah anda mengalami kesakitan yang bertambah teruk dengan aktiviti? "
          }
        },
        "specific": true,
        "index": 0,
        "frequency": 1,
        "required": true
      },
      "6315fe5270bec8c4b9036ae7": {
        "_id": "6315fe5270bec8c4b9036ae7",
        "name": "Stiffness Or Tenderness Along The Achilles Tendon After Waking Up",
        "question": "Do you feel any stiffness or pain along your achiles tendon after waking up?",
        "createdAt": "2022-09-05T13:49:06.219Z",
        "updatedAt": "2023-01-14T09:36:12.276Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda merasakan sebarang kekakuan atau sakit di sepanjang tendon achiles anda selepas bangun dari tidur?"
          }
        },
        "specific": true,
        "index": 1,
        "frequency": 0,
        "required": true
      },
      "6315fe2270bec8c4b9036ae1": {
        "_id": "6315fe2270bec8c4b9036ae1",
        "name": "Thickening Of Tendon At The Back Of The Ankle",
        "question": "Do you notice any thickening of tendon at the back of your ankle?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F91b7ec5f-bebb-47af-93c3-57fe645cecc2_b1e0c988ed794330892462d817e50889.jpg)",
        "createdAt": "2022-09-05T13:48:18.716Z",
        "updatedAt": "2023-01-13T21:27:00.745Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda melihat sebarang penebalan tendon di bahagian belakang buku lali anda?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F91b7ec5f-bebb-47af-93c3-57fe645cecc2_b1e0c988ed794330892462d817e50889.jpg)"
          }
        },
        "specific": true,
        "index": 1,
        "frequency": 1,
        "required": true
      },
      "6315f55eb96c1075087f7531": {
        "_id": "6315f55eb96c1075087f7531",
        "name": "Localised Pain Around Calf Area",
        "question": "Do you experience any pain confined to an area around your calf?\n",
        "createdAt": "2022-09-05T13:10:54.285Z",
        "updatedAt": "2023-01-01T12:42:09.713Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda mengalami sebarang kesakitan yang terhad di kawasan sekitar betis anda?"
          }
        },
        "specific": true,
        "index": 2,
        "frequency": 0,
        "required": true
      },
      "6315f4bbb96c1075087f752b": {
        "_id": "6315f4bbb96c1075087f752b",
        "name": "History Of Previous Venous Thromboembolic Event",
        "question": "Do you have any history of blood clots in the past?",
        "createdAt": "2022-09-05T13:08:11.599Z",
        "updatedAt": "2022-12-30T05:14:15.315Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Pernahkah anda mempunyai sejarah pembekuan darah pada masa lalu?"
          }
        },
        "specific": true,
        "index": 2,
        "frequency": 1,
        "required": true
      },
      "6315f45ab96c1075087f7525": {
        "_id": "6315f45ab96c1075087f7525",
        "name": "History Of Immobilization Previous Surgery Trauma",
        "question": "Do you have any history of immobilisation such that you were not able to move freely for a long time, history of any surgical intervention done or history of trauma in the past?",
        "createdAt": "2022-09-05T13:06:34.344Z",
        "updatedAt": "2022-12-30T05:06:22.412Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda mempunyai sejarah imobilisasi sehingga anda tidak dapat bergerak bebas untuk masa yang lama, sejarah sebarang pembedahan yang dilakukan atau sejarah trauma pada masa lalu?"
          }
        },
        "specific": true,
        "index": 2,
        "frequency": 2,
        "required": true
      },
      "63176a3814c362f3529a4e8f": {
        "_id": "63176a3814c362f3529a4e8f",
        "name": "History Of Iron Deficiency Anemia",
        "question": "Were you once diagnosed with iron deficiency anaemia?",
        "createdAt": "2022-09-06T15:41:44.311Z",
        "updatedAt": "2022-12-30T05:08:32.967Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda pernah didiagnosis dengan kekurangan zat besi?"
          }
        },
        "specific": true,
        "index": 3,
        "frequency": 0,
        "required": true
      },
      "63164cc0b10326d07c2a6ae5": {
        "_id": "63164cc0b10326d07c2a6ae5",
        "name": "Dysesthesias",
        "question": "Do you have any unusual sensations in your legs, such as burning or prickling?",
        "createdAt": "2022-09-05T19:23:44.480Z",
        "updatedAt": "2022-12-29T14:38:38.139Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda mempunyai sebarang sensasi yang luar biasa di kaki anda, seperti rasa pedih atau tercucuk?"
          }
        },
        "specific": true,
        "index": 3,
        "frequency": 1,
        "required": true
      },
      "62862492b13d4aa641901f7f": {
        "_id": "62862492b13d4aa641901f7f",
        "name": "Fever",
        "createdAt": "2022-05-19T11:05:54.783Z",
        "updatedAt": "2023-01-07T15:06:05.209Z",
        "__v": 0,
        "question": "Do you have a fever?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F1cb265ab-e424-4256-b1ec-4e5a3b3a157e_fever%2520edited.JPG)",
        "translations": {
          "zh": {
            "name": "发烧",
            "question": "您现在有没有发烧？"
          },
          "ms": {
            "question": "Adakah anda demam?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F1cb265ab-e424-4256-b1ec-4e5a3b3a157e_fever%2520edited.JPG)"
          }
        },
        "specific": true,
        "index": 4,
        "frequency": 0,
        "required": true
      },
      "6315fbb070bec8c4b9036aa0": {
        "_id": "6315fbb070bec8c4b9036aa0",
        "name": "Orange Peel Appearance",
        "question": "Does the affected site look pitted, like an orange skin?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F5268d68a-64f9-42b8-8ebd-1d0aa5d5a14b_40257_2021_659_Fig1_HTML.jpg)",
        "createdAt": "2022-09-05T13:37:52.997Z",
        "updatedAt": "2023-01-05T16:53:16.730Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah tapak yang terlibat kelihatan berlubang, seperti kulit oren?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F5268d68a-64f9-42b8-8ebd-1d0aa5d5a14b_40257_2021_659_Fig1_HTML.jpg)"
          }
        },
        "specific": true,
        "index": 4,
        "frequency": 0,
        "required": true
      },
      "6315fa4170bec8c4b9036a9a": {
        "_id": "6315fa4170bec8c4b9036a9a",
        "name": "Acute Onset Erythematous Tender Warm Oedematous Unilateral Lower Limb",
        "question": "Does your affected leg suddenly become red, swollen, warm, and painful to touch?",
        "createdAt": "2022-09-05T13:31:45.483Z",
        "updatedAt": "2022-12-28T12:06:29.204Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah kaki anda tiba-tiba menjadi merah, bengkak, hangat dan sakit apabila disentuh?"
          }
        },
        "specific": true,
        "index": 4,
        "frequency": 1,
        "required": true
      },
      "63164b10b10326d07c2a6ad6": {
        "_id": "63164b10b10326d07c2a6ad6",
        "name": "Intermittent Claudication",
        "question": "Do you experience leg pain during activities such as walking that normally resolves at rest?",
        "createdAt": "2022-09-05T19:16:32.301Z",
        "updatedAt": "2023-01-01T11:43:43.302Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda mengalami sakit kaki semasa melakukan aktiviti seperti berjalan yang biasanya hilang semasa rehat?"
          }
        },
        "specific": true,
        "index": 5,
        "frequency": 0,
        "required": false
      },
      "63a9e7a761f5305404d18447": {
        "_id": "63a9e7a761f5305404d18447",
        "name": "Gangrenous Discoloration Of Lower Limb",
        "question": "Do you have progressive worsening black or purple discoloration of skin over your leg? \n\n![](https://storage.googleapis.com/mediverse/uploads%2F3b106953-a3e0-4407-b9b1-f427aeabdfa4_images.jpg)\n",
        "createdAt": "2022-12-26T18:27:51.294Z",
        "updatedAt": "2023-01-07T15:14:08.773Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda mengalami perubahan warna hitam atau ungu yang semakin teruk pada bahagian kaki anda?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F3b106953-a3e0-4407-b9b1-f427aeabdfa4_images.jpg)\n"
          }
        },
        "specific": true,
        "index": 6,
        "frequency": 2,
        "required": false
      },
      "63a9e6d261f5305404d18441": {
        "_id": "63a9e6d261f5305404d18441",
        "name": "Skin Bullae Over Lower Limb",
        "question": "Do you have skin bullae (large blisters on the skin that are filled with fluid or pus) over your leg? \n\n![](https://storage.googleapis.com/mediverse/uploads%2Fb18ec2ef-8578-4c59-b449-7509389f8936_Screenshot%25202022-12-27%2520022341.png)",
        "createdAt": "2022-12-26T18:24:18.412Z",
        "updatedAt": "2023-01-14T20:56:32.120Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda mempunyai bula kulit (lepuh besar pada kulit yang dipenuhi cecair atau nanah) di atas kaki anda?\n\n![](https://storage.googleapis.com/mediverse/uploads%2Fb18ec2ef-8578-4c59-b449-7509389f8936_Screenshot%25202022-12-27%2520022341.png)"
          }
        },
        "specific": true,
        "index": 6,
        "frequency": 1,
        "required": false
      },
      "63031f5e07871381fb1b44aa": {
        "_id": "63031f5e07871381fb1b44aa",
        "name": "Limited Range Of Motion",
        "createdAt": "2022-08-22T06:17:02.659Z",
        "updatedAt": "2023-01-01T12:40:55.523Z",
        "__v": 0,
        "question": "Do you have reduced range of motion of the affected limb?",
        "translations": {
          "ms": {
            "question": "Adakah anda telah mengurangkan julat pergerakan anggota yang terlibat?"
          }
        },
        "specific": false,
        "index": 0,
        "frequency": 2,
        "required": true
      },
      "63031f8907871381fb1b4501": {
        "_id": "63031f8907871381fb1b4501",
        "name": "Crepitus",
        "createdAt": "2022-08-22T06:17:45.323Z",
        "updatedAt": "2022-12-29T13:57:42.904Z",
        "__v": 0,
        "question": "Do you hear a popping, crackling or clicking sound in the joint?",
        "translations": {
          "ms": {
            "question": "Adakah anda mendengar bunyi meletus, berderak atau klik pada sendi?"
          }
        },
        "specific": false,
        "index": 0,
        "frequency": 0,
        "required": true
      },
      "63031f8107871381fb1b44fb": {
        "_id": "63031f8107871381fb1b44fb",
        "name": "Stiffness Of The Joint",
        "createdAt": "2022-08-22T06:17:37.285Z",
        "updatedAt": "2023-01-14T09:36:26.649Z",
        "__v": 0,
        "question": "Do you have stiffness of the joint?",
        "translations": {
          "ms": {
            "question": "Adakah anda mengalami kekejangan sendi?"
          }
        },
        "specific": false,
        "index": 0,
        "frequency": 1,
        "required": true
      },
      "63032bf5e4861a3bf766b769": {
        "_id": "63032bf5e4861a3bf766b769",
        "name": "Knee Giving Away Or Locking",
        "createdAt": "2022-08-22T07:10:45.363Z",
        "updatedAt": "2022-08-25T12:29:31.198Z",
        "__v": 0,
        "question": "Do your knees give away or lock?",
        "specific": false,
        "index": 0,
        "frequency": 2,
        "required": true
      },
      "63032c06e4861a3bf766b784": {
        "_id": "63032c06e4861a3bf766b784",
        "name": "Genu Valgus Or Varus",
        "createdAt": "2022-08-22T07:11:02.807Z",
        "updatedAt": "2023-01-07T15:15:01.056Z",
        "__v": 0,
        "question": "Do you have knock knees or bowlegs?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F959fdbc3-e0df-4101-b528-e6f066cc9ed9_genu%2520valgus%2520varus.jpeg)",
        "translations": {
          "ms": {
            "question": "Adakah anda mempunyai lutut pengkar luar atau lutut pengkar dalam?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F959fdbc3-e0df-4101-b528-e6f066cc9ed9_genu%2520valgus%2520varus.jpeg)"
          }
        },
        "specific": false,
        "index": 0,
        "frequency": 1,
        "required": true
      },
      "63032bfbe4861a3bf766b76f": {
        "_id": "63032bfbe4861a3bf766b76f",
        "name": "Bony Swellings Around Knee Joint",
        "createdAt": "2022-08-22T07:10:51.994Z",
        "updatedAt": "2022-12-28T12:33:20.297Z",
        "__v": 0,
        "question": "Do you have any bony swellings around the knee joint?",
        "translations": {
          "ms": {
            "question": "Adakah anda mengalami bengkak tulang di sekitar sendi lutut?"
          }
        },
        "specific": false,
        "index": 0,
        "frequency": 2,
        "required": true
      },
      "6315feafb96c1075087f753d": {
        "_id": "6315feafb96c1075087f753d",
        "name": "Swollen Ankle",
        "question": "Do you notice any swelling around your ankle?\n\n![](https://storage.googleapis.com/mediverse/uploads%2Fe88065bf-5813-4d4c-afdd-3a69e5fc1623_32b_moved.jpg)",
        "createdAt": "2022-09-05T13:50:39.371Z",
        "updatedAt": "2023-01-14T09:26:21.553Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda perasan bengkak di sekitar buku lali anda?\n\n![](https://storage.googleapis.com/mediverse/uploads%2Fe88065bf-5813-4d4c-afdd-3a69e5fc1623_32b_moved.jpg)"
          }
        },
        "specific": false,
        "index": 1,
        "frequency": 0,
        "required": true
      },
      "6315ff2ab96c1075087f7540": {
        "_id": "6315ff2ab96c1075087f7540",
        "name": "Erythematous Ankle",
        "question": "Does your affected ankle look red and warm?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F0d6be832-d6ab-4794-9570-a344635d0737_Achilles-tendon-1.jpg)",
        "createdAt": "2022-09-05T13:52:42.478Z",
        "updatedAt": "2023-01-07T15:02:16.571Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah buku lali anda yang terlibat kelihatan merah dan panas bila disentuh?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F0d6be832-d6ab-4794-9570-a344635d0737_Achilles-tendon-1.jpg)"
          }
        },
        "specific": false,
        "index": 1,
        "frequency": 0,
        "required": true
      },
      "6315f65d70bec8c4b9036a4d": {
        "_id": "6315f65d70bec8c4b9036a4d",
        "name": "Skin Redness And Warmth Of Unilateral Lower Limb",
        "question": "Does your affected leg appear red and feel warm to the touch?\n![](https://storage.googleapis.com/mediverse/uploads%2F5e2a7a20-71f5-4761-8ece-7a0e34218178_DVT-Deep-Vein-Thrombosis-image.jpg)",
        "createdAt": "2022-09-05T13:15:09.978Z",
        "updatedAt": "2023-01-14T20:55:56.684Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah kaki anda yang terjejas kelihatan merah dan terasa hangat apabila disentuh?\n![](https://storage.googleapis.com/mediverse/uploads%2F5e2a7a20-71f5-4761-8ece-7a0e34218178_DVT-Deep-Vein-Thrombosis-image.jpg)"
          }
        },
        "specific": false,
        "index": 2,
        "frequency": 3,
        "required": true
      },
      "6315f6bdb96c1075087f7537": {
        "_id": "6315f6bdb96c1075087f7537",
        "name": "Unilateral Oedema Of Leg",
        "question": "Does your affected leg look swollen?\n![](https://storage.googleapis.com/mediverse/uploads%2F9aaa8900-5eed-4410-bab8-235a6ef14901_1200px-Deep_vein_thrombosis_of_the_right_leg.jpg)",
        "createdAt": "2022-09-05T13:16:45.733Z",
        "updatedAt": "2023-01-13T19:26:27.726Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah kaki anda yang terjejas kelihatan bengkak?\n![](https://storage.googleapis.com/mediverse/uploads%2F9aaa8900-5eed-4410-bab8-235a6ef14901_1200px-Deep_vein_thrombosis_of_the_right_leg.jpg)"
          }
        },
        "specific": false,
        "index": 2,
        "frequency": 2,
        "required": true
      },
      "6315f95870bec8c4b9036a53": {
        "_id": "6315f95870bec8c4b9036a53",
        "name": "Prominent Superficial Veins Over Foot And Leg",
        "question": "Do you notice any obvious vein swellings on your affected leg?\n\n![](https://storage.googleapis.com/mediverse/uploads%2Fd38644d6-deae-4d5e-8056-279338ceb494_Varicose_Veins_Outdoors-732x549-Thumbnail.jpg)",
        "createdAt": "2022-09-05T13:27:52.600Z",
        "updatedAt": "2023-01-06T03:13:13.079Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda perasan sebarang pembengkakan urat yang jelas pada kaki anda yang terlibat?\n\n![](https://storage.googleapis.com/mediverse/uploads%2Fd38644d6-deae-4d5e-8056-279338ceb494_Varicose_Veins_Outdoors-732x549-Thumbnail.jpg)"
          }
        },
        "specific": false,
        "index": 2,
        "frequency": 1,
        "required": true
      },
      "631791b430680e6c20369fcd": {
        "_id": "631791b430680e6c20369fcd",
        "name": "Evening Pain Symptoms",
        "question": "Do your leg symptoms happen mostly at night?",
        "createdAt": "2022-09-06T18:30:12.068Z",
        "updatedAt": "2022-12-29T13:47:59.206Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah gejala kaki anda kebanyakannya berlaku pada waktu malam?"
          }
        },
        "specific": false,
        "index": 3,
        "frequency": 1,
        "required": true
      },
      "631791eb7a04e9db04b44163": {
        "_id": "631791eb7a04e9db04b44163",
        "name": "Symptoms Decrease With Movement Stretching Or Massage",
        "question": "Do moving your legs, stretching, or massaging relieve your symptoms?",
        "createdAt": "2022-09-06T18:31:07.080Z",
        "updatedAt": "2023-01-13T21:43:41.843Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah menggerakkan kaki anda, meregangkan, atau mengurut melegakan gejala anda?"
          }
        },
        "specific": false,
        "index": 3,
        "frequency": 0,
        "required": true
      },
      "631792287a04e9db04b44169": {
        "_id": "631792287a04e9db04b44169",
        "name": "Alcohol Use Prior To Bedtime Worsens Symptoms",
        "question": "Does taking alcohol before bedtime make your symptoms worse?",
        "createdAt": "2022-09-06T18:32:08.469Z",
        "updatedAt": "2022-12-28T12:15:11.470Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah minuman alkohol sebelum tidur memburukkan gejala anda?"
          }
        },
        "specific": false,
        "index": 3,
        "frequency": 0,
        "required": true
      },
      "631792677a04e9db04b4416f": {
        "_id": "631792677a04e9db04b4416f",
        "name": "Caffeine Use Prior To Bedtime Worsens Symptoms",
        "question": "Does caffeine intake, such as tea or coffee, before bedtime make your symptoms worse?",
        "createdAt": "2022-09-06T18:33:11.569Z",
        "updatedAt": "2022-12-29T05:51:41.546Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah pengambilan kafein, seperti teh atau kopi, sebelum tidur memburukkan gejala anda?"
          }
        },
        "specific": false,
        "index": 3,
        "frequency": 0,
        "required": true
      },
      "629c3f188b6921fa74aa91ef": {
        "_id": "629c3f188b6921fa74aa91ef",
        "name": "Poor Glycemic Control In Long Standing Diabetes Mellitus",
        "createdAt": "2022-06-05T05:28:56.574Z",
        "updatedAt": "2023-01-05T17:25:42.919Z",
        "__v": 0,
        "question": "Do you have poor control of your long-standing Diabetes?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F5c177e02-88c7-4fd8-a248-a2268b278e8c_poor%2520glycemic%2520control.png)\n![](https://storage.googleapis.com/mediverse/uploads%2F25b8e639-d94a-439f-a32a-29f6dad540d0_glucoseee.jfif)",
        "translations": {
          "zh": {
            "name": "血糖不受控制",
            "question": "身为一位糖尿病患者，您的血糖是否一直 不受控制？\n\n![](https://storage.googleapis.com/mediverse/uploads%2F2978a52f-1e08-4766-ac1d-02c4966725b4_20191122223555713711.png)"
          },
          "ms": {
            "question": "Adakah anda mempunyai kawalan yang lemah terhadap Kencing Manis anda?\n\n![](https://storage.googleapis.com/mediverse/uploads%2F5c177e02-88c7-4fd8-a248-a2268b278e8c_poor%2520glycemic%2520control.png)\n![](https://storage.googleapis.com/mediverse/uploads%2F25b8e639-d94a-439f-a32a-29f6dad540d0_glucoseee.jfif)"
          }
        },
        "specific": false,
        "index": 4,
        "frequency": 2,
        "required": true
      },
      "6315fc3270bec8c4b9036ac1": {
        "_id": "6315fc3270bec8c4b9036ac1",
        "name": "Blistering",
        "question": "Do you notice any blister formations of your skin over the affected area?\n\n![](https://storage.googleapis.com/mediverse/uploads%2Fec7b960d-dd75-456c-b80e-2313ea549b72_cellulitis_b_high.jpg)",
        "createdAt": "2022-09-05T13:40:02.537Z",
        "updatedAt": "2023-01-07T14:19:33.924Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda perasan sebarang pembentukan lepuh pada kulit anda di kawasan yang terlibat?\n\n![](https://storage.googleapis.com/mediverse/uploads%2Fec7b960d-dd75-456c-b80e-2313ea549b72_cellulitis_b_high.jpg)"
          }
        },
        "specific": false,
        "index": 4,
        "frequency": 1,
        "required": true
      },
      "629d9338c7205d20cce8d342": {
        "_id": "629d9338c7205d20cce8d342",
        "name": "Fatigue",
        "createdAt": "2022-06-06T05:40:08.824Z",
        "updatedAt": "2022-12-29T16:44:38.499Z",
        "__v": 0,
        "question": "Do you feel extremely tired?",
        "translations": {
          "zh": {
            "name": "疲勞",
            "question": "您会不会感觉十分 累、提不起勁、或沒精神？"
          },
          "ms": {
            "question": "Adakah anda berasa sangat letih?"
          }
        },
        "specific": false,
        "index": 4,
        "frequency": 0,
        "required": true
      },
      "63164ba6b10326d07c2a6adc": {
        "_id": "63164ba6b10326d07c2a6adc",
        "name": "Sudden Onset Of Severe Leg Pain With Numbness Weakness Pale And Cold Leg",
        "question": "Do you experience sudden changes in your legs such that they become painful, numb, weak, pale, and cold to touch?",
        "createdAt": "2022-09-05T19:19:02.535Z",
        "updatedAt": "2023-01-14T09:32:54.161Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda mengalami perubahan mendadak pada kaki anda sehingga menjadi sakit, kebas, lemah, pucat, dan sejuk untuk disentuh?"
          }
        },
        "specific": false,
        "index": 5,
        "frequency": 2,
        "required": false
      },
      "63164bd390581bbb60627c12": {
        "_id": "63164bd390581bbb60627c12",
        "name": "Leg Pain At Rest",
        "question": "Does your leg pain occur during rest time?",
        "createdAt": "2022-09-05T19:19:47.180Z",
        "updatedAt": "2023-01-01T12:39:33.387Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah sakit kaki anda berlaku semasa waktu rehat?"
          }
        },
        "specific": false,
        "index": 5,
        "frequency": 1,
        "required": false
      },
      "63164c44b10326d07c2a6ae2": {
        "_id": "63164c44b10326d07c2a6ae2",
        "name": "Shiny Scaly Skin Over Lower Limb",
        "question": "Do you notice skin changes over your legs such that they become shiny and scaly?\n![](https://storage.googleapis.com/mediverse/uploads%2F9c32e878-ba26-4f10-aa6f-fbc1199bf394_IMG_8173-768x1024-1.jpg)",
        "createdAt": "2022-09-05T19:21:40.375Z",
        "updatedAt": "2023-01-14T21:01:31.370Z",
        "__v": 0,
        "translations": {
          "ms": {
            "question": "Adakah anda perasan perubahan kulit pada kaki anda sehingga menjadi berkilat dan bersisik?\n![](https://storage.googleapis.com/mediverse/uploads%2F9c32e878-ba26-4f10-aa6f-fbc1199bf394_IMG_8173-768x1024-1.jpg)"
          }
        },
        "specific": false,
        "index": 5,
        "frequency": 0,
        "required": false
      },
      "62b48c123a58e2bcf73b9bfd": {
        "_id": "62b48c123a58e2bcf73b9bfd",
        "name": "Lower Limb Swelling",
        "createdAt": "2022-06-23T15:51:46.583Z",
        "updatedAt": "2023-01-01T12:46:58.632Z",
        "__v": 0,
        "question": "Do you have any leg swelling?\n\n",
        "translations": {
          "zh": {
            "name": "下肢肿大",
            "question": "您的脚会不会肿？"
          },
          "ms": {
            "question": "Adakah anda mengalami bengkak kaki?"
          }
        },
        "specific": false,
        "index": 6,
        "frequency": 1,
        "required": false
      }
    },
    "bloodPressure": {
      "systolic": "100",
      "diastolic": "60"
    },
    "pulseRate": "90",
    "temperature": "37",
    "pulseOximetry": "98"
  },*/
	details: {},
	language: 'en',
	conditions: {},
	startTime: null,
	activity: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CURRENT_USER':
			return { ...state, user: action.payload };
		case 'DIALOG':
			return { ...state, dialog: action.payload };
		case 'LOADING':
			return { ...state, loading: action.payload };
		case 'DETAILS':
			return { ...state, details: action.payload };
		case 'LANGUAGE':
			return { ...state, language: action.payload };
		case 'CONDITIONS':
			return { ...state, conditions: action.payload };
		case 'STARTTIME':
			return { ...state, startTime: action.payload };
		case 'ACTIVITY':
			return { ...state, activity: action.payload };
		case 'MERGE_DETAILS':
			const clone = _.cloneDeep(state.details);
			if (action.payload === null || action.payload === '') {
				_.unset(clone, action.path);
			} else {
				_.set(clone, action.path, action.payload);
			}
			// console.log("details", clone);
			return { ...state, details: clone };
		default:
			return state;
	}
};

export default reducer;
