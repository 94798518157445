import SelectionModal from "./SelectionModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
	Dimensions,
	Platform,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import globalStyles from "../../styles/global.css";
import { AntDesign, Entypo } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { api, redux } from "../helper";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useRoute } from "@react-navigation/native";
import { Dialog } from "@rneui/themed";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

const InvitationDialog = ({
	isVisible,
	onAccept,
	onReject,
	onClose,
	title,
	content,
	handleInvitation,
	handleClose,
	correctUser,
}) => {
	// console.log(correctUser)
	return (
		<Dialog
			isVisible={isVisible}
			overlayStyle={{
				backgroundColor: "white",
				width: "450px",
				borderRadius: 10,
			}}
		>
			<Dialog.Title title={title} />
			<Text>{content}</Text>
			<Dialog.Actions>
				{onAccept && (
					<Dialog.Button
						title='Accept'
						onPress={() => {
							handleInvitation("Accepted");
						}}
						type='solid'
						radius={10}
						// titleStyle={{color: '#003139'}}
					/>
				)}
				{onReject && (
					<Dialog.Button
						title='Reject'
						onPress={() => {
							handleInvitation("Rejected");
						}}
						type='outline'
						radius={10}
						titleStyle={{ color: "#E74C3C" }}
						buttonStyle={{ borderColor: "#E74C3C" }}
					/>
				)}
				{onClose && <Dialog.Button title='Close' onPress={()=>handleClose(correctUser)} />}
			</Dialog.Actions>
		</Dialog>
	);
};
const Header = props => {
	const { setLanguage, language, Translate, user, navigation } = props;

	const [languageModal, setLanguageModal] = useState(false);
	const languagesList = [
		{ name: "English", value: "en" },
		{ name: "简体中文", value: "zh" },
		{ name: "Bahasa Malaysia", value: "ms" },
	];
	const [visibleDialog, setVisibleDialog] = useState({
		visible: false,
		title: "",
		text: "",
		onAccept: false,
		onReject: false,
		onClose: false,
		correctUser: true
	});

	const selectedLanguage = _.find(languagesList, { value: language });
	const [invitation, setInvitation] = useState();
	useEffect(() => {
		if (Platform.OS === "web") {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			if (urlParams.get("invitationCode")) {
				api("invitations", "get", {
					code: urlParams.get("invitationCode"),
				}).then(res => {
					setInvitation(res.result);
					if (res.result.status === "Expired") {
						setVisibleDialog({
							visible: true,
							title: "Invitation",
							text: "This invitation link is expired",
							onAccept: false,
							onReject: false,
							onClose: true,
						});
					} else if (
						res.result.status === "Pending" &&
						res.result.type === "Email" && user && 
						res.result.email !== user.email
					) {
						setVisibleDialog({
							visible: true,
							title: "Invitation",
							text: "This invitation link is not valid for this user. Please try with other account",
							onAccept: false,
							onReject: false,
							onClose: true,
							correctUser: false

						});
					} else if (!res.result._organizationId) {
						setVisibleDialog({
							visible: true,
							title: "Invitation",
							text: "This invitation link is not yet activate or invalid. Please wait the admin create the organization first",
							onAccept: false,
							onReject: false,
							onClose: true,
						});
					} else if (res.result._organizationId && user && !user.organization) {
						setVisibleDialog({
							visible: true,
							title: "Invitation",
							text: `You are invited to joined ${res.result.role} of ${res.result._organizationId.name} organization.`,
							onAccept: true,
							onReject: true,
							onClose: false,
						});
					} else if (
						res.result._organizationId &&
						user &&
						user.organization &&
						res.result._organizationId._id !==
							user.organization._organizationId._id
					) {
						setVisibleDialog({
							visible: true,
							title: "Invitation",
							text: `You already is the ${user.organization.role} of ${user.organization._organizationId.name}. You cannot join two organization in at same time.`,
							onAccept: false,
							onReject: false,
							onClose: true,
						});
					}
				});
			}
		}
	}, [window.location]);
	// console.log(invitation);
	const handleClose = (correctUser) => {
		setVisibleDialog({
			visible: false,
			title: "",
			text: "",
			onAccept: false,
			onReject: false,
			onClose: false,
			correctUser: true
		});
		if (Platform.OS === "web" && correctUser) {
			const url = new URL(window.location.href);
			url.searchParams.delete("invitationCode");
			window.history.pushState({}, "", url);
		} else {
			navigation.navigate("home");
		}
	};
	const handleInvitation = async status => {
		// console.log(user);
		const data = {
			...invitation,
			status: status,
			_userId: user._id || user._doc._id,
			email: user.email,
			_organizationId: invitation._organizationId._id,
		};
		// console.log(status, data);
		await api("invitations/update", "post", data).then(res => {
			setVisibleDialog({
				visible: false,
				title: "",
				text: "",
				onAccept: false,
				onReject: false,
				onClose: false,
			});
			if (Platform.OS === "web") {
				const url = new URL(window.location.href);
				url.searchParams.delete("invitationCode");
				window.history.pushState({}, "", url);
				window.location.href = url;
			} else {
				navigation.navigate("home");
			}
		});
	};
	// console.log(user);
	return (
		<>
			<SelectionModal
				title={"Languages"}
				modal={languageModal}
				Translate={Translate}
				setModal={setLanguageModal}
				options={languagesList}
				onSelected={(item, path) => {
					AsyncStorage.setItem("language", item.value);
					setLanguage(item.value);
				}}
				selection={false}
			/>

			<InvitationDialog
				isVisible={visibleDialog.visible}
				title={visibleDialog.title}
				content={visibleDialog.text}
				handleClose={handleClose}
				handleInvitation={handleInvitation}
				onAccept={visibleDialog.onAccept}
				onClose={visibleDialog.onClose}
				onReject={visibleDialog.onReject}
				correctUser={visibleDialog.correctUser}
			/>

			<View
				style={{
					//height: 60,
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					marginHorizontal: 20,
				}}
			>
				{navigation.toggleDrawer ? (
					<TouchableOpacity
						style={{ flex: 1 }}
						onPress={() => {
							navigation.toggleDrawer();
							/*if(confirm("Do you wish to Sign Out?")) {
              signOut(auth)
            }*/
						}}
					>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Entypo name='menu' size={30} color='white' />
							<View style={{ marginLeft: 10 }}>
								<Text
									style={[
										globalStyles.font_FamilyMedium,
										globalStyles.fontMD,
										{ color: "white" },
									]}
								>
									{user?.displayName}
								</Text>
								<Text
									style={[
										globalStyles.font_FamilyLight,
										{
											color: "white",
											marginTop: -5,
											...(user.organization && { fontSize: 10 }),
										},
									]}
								>
									{user?.email}
								</Text>
								{user.organization && (
									<Text
										style={[
											globalStyles.font_FamilyLight,
											{
												color: "white",
												marginTop: -5,
												fontSize: "10px",
											},
										]}
									>
										{user?.organization._organizationId.name}
									</Text>
								)}
							</View>
						</View>
					</TouchableOpacity>
				) : (
					<View style={{ flex: 1 }} />
				)}
				<TouchableOpacity
					onPress={() => {
						setLanguageModal({
							title: "Languages",
						});
					}}
					style={{
						width: Platform.OS === "web" ? 130 : componentHeight * 0.46,
						height: Platform.OS === "web" ? 40 : componentHeight * 0.096,
						backgroundColor: "rgba(255,255,255,0.20)",
						borderRadius: 25,
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "row",
						paddingRight: 15,
						paddingLeft: 15,
					}}
				>
					<Text
						style={[
							globalStyles.fontXS,
							{
								color: "white",
								flex: 1,
								marginRight: 10,
							},
						]}
					>
						{selectedLanguage?.name}
					</Text>
					<AntDesign name='caretdown' size={14} color='white' />
				</TouchableOpacity>
			</View>
		</>
	);
};

export default redux(["language", "user"], ["setLanguage"])(Header);
