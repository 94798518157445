import {Text, View, Platform, TextInput, TouchableOpacity, ScrollView} from "react-native";
import globalStyles from "../../styles/global.css";
import React, {useEffect, useState} from "react";
import Button from "../Components/Button";
import {api, redux, theme} from "../helper";
import {Entypo} from "@expo/vector-icons";
import SelectionModal from "../Components/SelectionModal";

const HealthDetails = ({setLoading, Translate, setCurrentUser, user}) => {

  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState(user);
  const [knownIllnesses, setKnownIllnesses] = useState([]);

  useEffect(() => {
    api("known-illnesses").then((res) => {
      setKnownIllnesses(res.result);
    });
  }, []);

  const onChange = (path, data) => {
    const clone = _.cloneDeep(details)
    if(data === null) {
      _.unset(clone, path)
    } else {
      _.set(clone, path, data)
    }
    setDetails(clone)
  }

  const save = async () => {
    setLoading(true)
    try {
      const profile = await api('users/profile', 'put', details)
        .then(res => res.result)
      setCurrentUser(profile)
    } catch (e) {
      alert(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ScrollView contentContainerStyle={{ flex: 1, padding: 10 }}>
      <SelectionModal
        modal={modal}
        setModal={setModal}
        Translate={Translate}
        options={knownIllnesses}
        onSelected={(illness, path) => {
          onChange(`${path}.${illness._id}`, illness)
        }}
      />
      <View
        style={{
          backgroundColor: 'white',
          borderRadius: 20,
          padding: 20
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <View
            style={{
              flex: 1
            }}
          >
            <Text
              style={[
                globalStyles.font_FamilyMedium
              ]}
            >
              Do you have any previous health problems or known disease?
            </Text>
          </View>

          <TouchableOpacity
            onPress={() => {
              setModal({
                title: Translate.getStatic("underlying_conditions"),
                path: "knownIllnesses",
                filter: {
                  type: "standard",
                },
              });
            }}
            style={{
              width: 40,
              height: 40,
              backgroundColor: theme.primary,
              borderRadius: 20,
              marginLeft: 10,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Entypo name="add-to-list" size={20} color="white" />
          </TouchableOpacity>
        </View>
        {_.map(_.get(details, 'knownIllnesses'), ({name, _id}) => {
          return (
            <TouchableOpacity
              key={_id}
              onPress={() => {
                onChange(`knownIllnesses.${_id}`, null)
              }}
              style={{
                backgroundColor: theme.primary,
                borderRadius: 10,
                padding: 10,
                marginTop: 10,
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <View
                style={{
                  flex: 1
                }}
              >
                <Text
                  style={[
                    globalStyles.font_FamilySemiBold,
                    {
                      color: 'white',
                    }
                  ]}
                >
                  {name}
                </Text>
              </View>
              <Entypo name="cross" size={24} color="white" />
            </TouchableOpacity>
          )
        })}
      </View>
      <View
        style={{
          backgroundColor: 'white',
          borderRadius: 20,
          padding: 20,
          marginTop: 10
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <View
            style={{
              flex: 1
            }}
          >
            <Text
              style={[
                globalStyles.font_FamilyMedium
              ]}
            >
              Do you have any conditions that run in your family?
            </Text>
          </View>

          <TouchableOpacity
            onPress={() => {
              setModal({
                title: Translate.getStatic("family_conditions"),
                path: "familyIllnesses",
                filter: {
                  type: "familyHistory",
                },
              });
            }}
            style={{
              width: 40,
              height: 40,
              backgroundColor: theme.primary,
              borderRadius: 20,
              marginLeft: 10,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Entypo name="add-to-list" size={20} color="white" />
          </TouchableOpacity>
        </View>
        {_.map(_.get(details, 'familyIllnesses'), ({name, _id}) => {
          return (
            <TouchableOpacity
              key={_id}
              onPress={() => {
                onChange(`familyIllnesses.${_id}`, null)
              }}
              style={{
                backgroundColor: theme.primary,
                borderRadius: 10,
                padding: 10,
                marginTop: 10,
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <View
                style={{
                  flex: 1
                }}
              >
                <Text
                  style={[
                    globalStyles.font_FamilySemiBold,
                    {
                      color: 'white',
                    }
                  ]}
                >
                  {name}
                </Text>
              </View>
              <Entypo name="cross" size={24} color="white" />
            </TouchableOpacity>
          )
        })}
      </View>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          margin: 15
        }}
      >
        <Button
          onPress={save}
        >
          Save
        </Button>
      </View>
    </ScrollView>
  );
};

const styles = {
  inputStyle: {
    height: 40,
    backgroundColor: '#eee',
    paddingHorizontal: 10,
    borderRadius: 10,
    alignItems: 'center',
    flexDirection: 'row'
  }
};
export default redux(['user'], ['setLoading', 'setCurrentUser'])(HealthDetails);