import React, { useEffect, useState } from "react";
import {Platform, ScrollView, Text, TouchableOpacity, View} from "react-native";
import {api, baseUrl, redux} from "../helper";
import { Slider, Switch } from "@rneui/themed";
import _ from "lodash";
// import Markdown from "react-native-markdown-renderer";
import globalStyles from "../../styles/global.css";
import Markdown from "react-native-markdown-display";
import Button from "../Components/Button";

function TriageDescriptionScreen(props) {
  const { route, navigation, setDetails, Translate } = props;

  const {condition, resultId} = route.params;
  Translate.load(condition);

  return (
    <View style={{ flex: 1, padding: 20 }}>
      <View style={{ flex: 1 }}>
        <ScrollView
          style={{
            backgroundColor: "white",
            height: "100%",
            borderRadius: 10,
            marginTop: 10,
            padding: 20,
            //marginBottom: 20,
            paddingVertical: 10,
          }}>
          <Markdown
            style={{
              body: globalStyles.fontSM,
            }}>
            {Translate.get("triage.description") || Translate.getStatic("no_info_yet")}
          </Markdown>
        </ScrollView>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
        }}>
        <Button
          style={{ flex: 1, marginRight: 5 }}
          onPress={() => {
            navigation.pop();
          }}>
          {Translate.getStatic("see_more")}
        </Button>
        <Button
          style={{ flex: 1, marginLeft: 5 }}
          onPress={() => {
            // setDetails({});
            // navigation.popToTop();
            // navigation.replace("home");
            navigation.replace("teleconsultation", {
          
              resultId
            });
          }}>
          {Translate.getStatic("next")}
        </Button>
      </View>
      {/* {Platform.OS === 'web' &&
        <Button
          style={{ marginTop: 15, width: '100%' }}
          onPress={() => {
            window.open(`${baseUrl}/api/conditions/pdf/${resultId}`, '_blank')
          }}
        >
          {Translate.get('teleconsultation.download')}
        </Button>
      } */}
    </View>
  );
}

export default redux([], ["setDetails"])(TriageDescriptionScreen);
