import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  Dimensions,
} from "react-native";
import { api, redux, Translation, theme } from "../helper";
import {} from "@rneui/themed";
import _ from "lodash";
import { Entypo, FontAwesome, Ionicons } from "@expo/vector-icons";
import Markdown from "react-native-markdown-display";
import Button from "../Components/Button";
import globalStyles from "../../styles/global.css";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function PresentationsScreen(props) {
  const { details, navigation, mergeDetails, Translate, setDetails } = props;

  const [conditions, setConditions] = useState([]);
  const [presentations, setPresentations] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [condition, setCondition] = useState(null);

  useEffect(() => {
    api(
      `conditions/${_.get(details, "symptom._id")}/presentations`,
      "post",
      details
    ).then((res) => {
      const { conditions, presentations } = res.result;
      setConditions(conditions);
      setPresentations(presentations);
    });

    navigation.setOptions({
      headerTitle: "",
      headerLeft: () => {
        return (
          <TouchableOpacity
            style={{
              padding: 15,
            }}
            onPress={() => {
              navigation.replace("home");
              setDetails({});
            }}>
            <Entypo name="home" size={30} color={theme.primary} />
          </TouchableOpacity>
        );
      },
    });
  }, []);

  /*  useEffect(() => {
    if(_.isEmpty(details.presentations)) {
      return setCondition(null)
    }
    const presentationIds = _.map(details.presentations, presentation => presentation._id);

    const condition = _.find(conditions, condition => {
      const match = _.map(condition.specificPresentations, _id => {
        return _.includes(presentationIds, _id)
      })
      return _.every(match)
    })

    setCondition(condition)
  }, [details])*/

  useEffect(() => {
    if (!presentations.length) {
      return;
    }
    const next = getNextPresentation();
    if (!next) {
      mergeDetails("condition", condition);
      navigation.replace('vitals')
    }
  }, [answered, presentations]);

  const onPress = (answer, presentation) => {
    if (answer === "yes") {
      mergeDetails(`presentations.${presentation._id}`, presentation);
    }
    const clone = _.cloneDeep(answered);
    clone.push({ _id: presentation._id, answer });
    setAnswered(clone);
  };

  const undo = () => {
    const clone = _.cloneDeep(answered);
    const presentation = _.findLast(clone);
    clone.pop();
    mergeDetails(`presentations.${presentation._id}`, null);
    setAnswered(clone);
  };

  const getNextPresentation = () => {
    let next;

    /*if(!condition) {
      out = _.orderBy(presentations, 'specific', 'desc')
    } else {
      const merge = _.union(condition.specificPresentations, condition.nonSpecificPresentations)
      out = _.map(merge, _id => {
        return _.find(presentations, {_id})
      })
    }*/

    let out = _.filter(presentations, { specific: true, required: true });

    let groupByIndex = {};
    _.forEach(
      _.filter(presentations, { specific: true, required: false }),
      (item) => {
        let prev = _.get(groupByIndex, `${item.index}`, []);
        prev.push(item);
        _.set(groupByIndex, item.index, prev);
      }
    );
    _.forEach(groupByIndex, (arr) => {
      const first = _.get(_.orderBy(arr, "frequency", "desc"), "0");
      let filtered = _.filter(arr, { frequency: first.frequency });
      if (
        _.some(_.map(filtered, (p) => _.has(details, `presentations.${p._id}`)))
      ) {
        filtered = arr;
      }
      _.forEach(filtered, (item) => {
        out.push(item);
      });
    });

    _.forEach(conditions, (condition) => {
      let nonSpecifics = [];
      _.forEach(details?.presentations, ({ _id, specific }) => {
        if (specific && _.includes(condition.specificPresentations, _id)) {
          nonSpecifics = _.map(condition.nonSpecificPresentations, (_id) => {
            return _.find(presentations, { _id });
          });
        }
      });
      out = _.union(out, nonSpecifics);
    });

    _.forEach(out, (presentation) => {
      if (!_.find(answered, { _id: presentation?._id })) {
        next = presentation;
        return false;
      }
    });

    return next;
  };

  const nextPresentation = getNextPresentation();

  if (!nextPresentation) {
    return (
      <View
        style={{
          flex: 1,
          padding: 20,
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Text>Loading...</Text>
      </View>
    );
  }

  Translate.load(nextPresentation);

  return (
    <View style={{ flex: 1, padding: 20 }}>
      <View style={{ flex: 1 }}>
        <View style={{ alignItems: "center" }}>
          <Image
            style={{
              height: 125,
              width: 125,
            }}
            source={require("../../assets/thinking.png")}
          />
        </View>
        <ScrollView
          style={{
            backgroundColor: "white",
            borderRadius: 15,
            //flex: 1,
            marginBottom: 10,
            padding: 15,
          }}>
          <View style={{ flex: 1 }}>
            {/*
            <Text style={{fontWeight: 'bold'}}>
              {Translate.getStatic('do_you_have')} {Translate.get('name')}?
            </Text>
            */}
            <View style={{ marginTop: 0 }}>
              <Markdown
                style={{
                  body: globalStyles.fontMD,
                }}>
                {Translate.get("question")}
              </Markdown>
            </View>
          </View>
        </ScrollView>
      </View>
      <View style={{ flexDirection: "column", justifyContent: "center" }}>
        <View style={{ flexDirection: "row" }}>
          <Button
            onPress={() => onPress("yes", nextPresentation)}
            style={{
              flex: 1,
              marginRight: 5,
              backgroundColor: "#34e192",
              height: Platform.OS === "web" ? 50 : componentHeight * 0.1,
            }}
            size={"small"}>
            <Entypo name="check" size={30} color="white" />
          </Button>
          <Button
            onPress={() => onPress("no", nextPresentation)}
            style={{
              flex: 1,
              marginLeft: 5,
              backgroundColor: "#ef5a5c",
              height: Platform.OS === "web" ? 50 : componentHeight * 0.1,
            }}
            size={"small"}>
            <Entypo name="cross" size={30} color="white" />
          </Button>
        </View>
        {!_.isEmpty(answered) ? (
          <TouchableOpacity
            style={{ alignItems: "center", padding: 10, marginTop: 10, marginBottom: 0 }}
            onPress={undo}>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}>
              <Ionicons
                name="arrow-back"
                size={Platform.OS === "web" ? 20 : componentWidth * 0.08}
                color="black"
              />
              <Text
                style={[
                  globalStyles.fontSM,
                  globalStyles.font_FamilyMedium,
                  {
                    marginLeft: 5,
                  },
                ]}>
                {Translate.getStatic("previous_questions")}
              </Text>
            </View>
            {/*<Text
              style={[
                globalStyles.fontXS,
                globalStyles.font_FamilyLight,
                {
                  color: "red",
                },
              ]}>
              {Translate.getStatic("return_previous")}
            </Text>*/}
          </TouchableOpacity>
        ) : (
          <View
            style={{
              height: Platform.OS === "web" ? 50 : componentHeight * 0.1,
            }}
          />
        )}
      </View>
    </View>
  );
}

export default redux(
  ["details", "language"],
  ["mergeDetails", "setDetails"]
)(PresentationsScreen);
