import {
	Text,
	View,
	ImageBackground,
	Image,
	TouchableOpacity,
	ScrollView,
	TextInput,
	Linking,
} from "react-native";
import globalStyles from "../../styles/global.css";
import { api, redux } from "../helper";
import Button from "../Components/Button";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { NODE_ADMIN_URI } from "@env";

const Organization = ({ user }) => {
	const editData = user.organization;

	const photoUrl = _.get(editData?._organizationId, "logo");

	return (
		<ScrollView style={{ flex: 1 }}>
			<ImageBackground
				style={{
					flex: 1,
					position: "relative",
					alignItems: "center",
				}}
				imageStyle={{
					resizeMode: "cover",
					height: 160,
				}}
				source={require("../../assets/profile-bg.png")}
			>
				<TouchableOpacity
					style={{
						alignItems: "center",
					}}
				>
					<ImageBackground
						source={
							photoUrl
								? { uri: photoUrl }
								: require("../../assets/default-organization.jpg")
						}
						resizeMode={"cover"}
						style={{
							width: 100,
							height: 100,
							borderRadius: 50,
							overflow: "hidden",
							margin: 15,
							backgroundColor: "white",
							alignItems: "flex-end",
							justifyContent: "flex-end",
						}}
					></ImageBackground>
				</TouchableOpacity>
				{!editData && (
					<View
						style={{
							backgroundColor: "white",
							marginHorizontal: 20,
							borderRadius: 20,
							borderWidth: 1,
							borderColor: "#ddd",
							padding: 20,
							shadowColor: "#000",
							shadowOffset: { width: 0, height: 2 },
							shadowOpacity: 0.1,
							shadowRadius: 4,
							elevation: 3,
						}}
					>
						<Text
							style={{
								color: "#333",
								fontSize: 16,
								fontWeight: "500",
								textAlign: "center",
							}}
						>
							You are not a member of any organization yet.
						</Text>
					</View>
				)}
				{editData && (
					<View
						style={{
							backgroundColor: "white",
							marginHorizontal: 20,
							borderRadius: 20,
							borderWidth: 1,
							borderColor: "#eee",
						}}
					>
						<TextRow
							editData={editData}
							title={"Name"}
							field={"_organizationId.name"}
							type={"text"}
							placeholder={"Wee Chong Wei"}
						/>
						<TextRow
							editData={editData}
							title={"Address"}
							field={"_organizationId.address"}
							type={"text"}
							placeholder={"123 Street ABC, Country Posscode"}
						/>
						<TextRow
							editData={editData}
							title={"Phone Number"}
							field={"_organizationId.phone"}
							type={"text"}
							placeholder={"+60168527507"}
						/>
						<TextRow
							editData={editData}
							title={"Email"}
							field={"email"}
							type={"text"}
							placeholder={"test@example.com"}
						/>
						<TextRow
							editData={editData}
							title={"Role"}
							field={"role"}
							type={"text"}
							placeholder={"Member"}
						/>
					</View>
				)}

				<View
					style={{
						alignItems: "center",
						justifyContent: "center",
						margin: 15,
					}}
				>
					{editData && editData?.role !== "Member" && (
						<Button
							onPress={async () => {
								if (await Linking.canOpenURL(NODE_ADMIN_URI)) {
									try {
										await Linking.openURL(NODE_ADMIN_URI);
									} catch (error) {
										console.error("An error occurred", error);
									}
								} else {
									console.log("Can't handle url: " + url);
								}
							}}
						>
							Login as organization
						</Button>
					)}
				</View>
			</ImageBackground>
		</ScrollView>
	);
};

const TextRow = ({ editData, field, title, type, placeholder }) => {
	return (
		<View
			style={{
				height: 60,
				borderBottomWidth: 1,
				borderColor: "#eee",
				flexDirection: "row",
				alignItems: "center",
				paddingHorizontal: 20,
			}}
		>
			<View style={{ width: 100 }}>
				<Text style={[globalStyles.font_FamilyMedium]}>{title}</Text>
			</View>
			<View style={{ flex: 1 }}>
				{type === "text" && (
					<TextInput
						placeholder={placeholder}
						placeholderTextColor={"#c5c5c5"}
						style={[globalStyles.font_FamilySemiBold, styles.inputStyle]}
						value={_.get(editData, field, "")}
						disabled
					/>
				)}
			</View>
		</View>
	);
};

const styles = {
	inputStyle: {
		height: 40,
		backgroundColor: "#eee",
		paddingHorizontal: 10,
		borderRadius: 10,
		alignItems: "center",
		flexDirection: "row",
	},
};

export default redux(["user"], ["setLoading", "setCurrentUser"])(Organization);
