import React, { useEffect } from 'react';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { Provider as ProviderStore } from 'react-redux';
import { createStore } from 'redux';
import reducer from './redux/reducer';
import { theme } from './src/helper';
import { createTheme, ThemeProvider } from '@rneui/themed';
import Container from './Container';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'react-native';
import 'react-native-gesture-handler';

SplashScreen.preventAutoHideAsync();

import {
	useFonts,
	Poppins_300Light,
	Poppins_400Regular,
	Poppins_500Medium,
	Poppins_600SemiBold,
	Poppins_700Bold,
} from '@expo-google-fonts/poppins';
import AppLoading from 'expo-app-loading';
import { TimerProvider } from './src/Components/TimerProvider';

let store = createStore(reducer);

const paperTheme = {
	...DefaultTheme,
	roundness: 2,
	colors: {
		...DefaultTheme.colors,
		primary: theme.primary,
		//accent: '#ff0000',
	},
};

const RNETheme = createTheme({
	colors: {
		primary: theme.primary,
		secondary: theme.secondary,
	},
	Button: {
		raised: true,
	},
});

function App() {
	let [fontsLoaded] = useFonts({
		Poppins_300Light,
		Poppins_400Regular,
		Poppins_500Medium,
		Poppins_600SemiBold,
		Poppins_700Bold,
	});

	useEffect(() => {
		if (fontsLoaded) {
			SplashScreen.hideAsync();
		}
	}, [fontsLoaded]);

	if (!fontsLoaded) {
		return null;
	}

	return (
		<ProviderStore store={store}>
			<ThemeProvider theme={RNETheme}>
				<PaperProvider theme={paperTheme}>
					<TimerProvider>
						<StatusBar
							barStyle='dark-content'
							hidden={false}
							backgroundColor='transparent'
							translucent={true}
						/>
						<Container />
					</TimerProvider>
				</PaperProvider>
			</ThemeProvider>
		</ProviderStore>
	);
}

export default App;
