export const setCurrentUser = payload => {
	return {
		type: 'CURRENT_USER',
		payload,
	};
};

export const setDialog = payload => {
	return {
		type: 'DIALOG',
		payload,
	};
};

export const setLoading = payload => {
	return {
		type: 'LOADING',
		payload,
	};
};

export const setDetails = payload => {
	return {
		type: 'DETAILS',
		payload,
	};
};

export const setLanguage = payload => {
	return {
		type: 'LANGUAGE',
		payload,
	};
};

export const setConditions = payload => {
	return {
		type: 'CONDITIONS',
		payload,
	};
};

export const setStartTime = payload => {
	return {
		type: 'STARTTIME',
		payload,
	};
};
export const setActivity = payload => {
	return {
		type: 'ACTIVITY',
		payload,
	};
};

export const mergeDetails = (path, payload) => {
	return {
		type: 'MERGE_DETAILS',
		path,
		payload,
	};
};
