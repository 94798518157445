import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { AppState } from 'react-native';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
	const [elapsedSeconds, setElapsedSeconds] = useState(0);
	const intervalRef = useRef(null);
	useEffect(() => {
		const subscription = AppState.addEventListener('change', nextAppState => {
			if (nextAppState === 'active') {
				startTimer();
			} else {
				pauseTimer();
			}
		});

		return () => {
			subscription.remove();
			pauseTimer();
		};
	}, []);

	const startTimer = () => {
		if (!intervalRef.current) {
			intervalRef.current = setInterval(() => {
				setElapsedSeconds(prevSeconds => prevSeconds + 1);
			}, 1000);
		}
	};

	const pauseTimer = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	const resetTimer = () => {
		pauseTimer();
		setElapsedSeconds(0);
	};

	return (
		<TimerContext.Provider
			value={{ elapsedSeconds, startTimer, pauseTimer, resetTimer }}
		>
			{children}
		</TimerContext.Provider>
	);
};

export const useTimer = () => useContext(TimerContext);
