import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Image,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  SafeAreaView,
} from "react-native";
import { api, redux, theme } from "../helper";
import _ from "lodash";
import Button from "../Components/Button";
import validator from "validator";
import { GiftedChat, Bubble, InputToolbar } from "react-native-gifted-chat";
import { TypingAnimation } from "react-native-typing-animation";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
// const { v4: uuidv4 } = require("uuid");

function ChatScreen(props) {
  const { details, navigation, mergeDetails, Translate, route } = props;

  const chatRef = useRef();

  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [action, setAction] = useState("");
  const [_conditionId, setConditionId] = useState("");

  useEffect(() => {
    const { _conditionId, conditionName } = route.params || {};
    if (_conditionId) {
      setConditionId(_conditionId);
      sendDoctorReply(
        `Hi, I am Dr. Hippo, you may ask me anything about ${conditionName}.`
      );
      setAction("condition");
    } else {
      sendDoctorReply(
        "Welcome to Mediverse!\r\n\r\nOur friendly AI assistant is here to help you with any questions you may have regarding your health. While we can assist with information regarding any medical conditions and answering your general health questions, please note that it is not intended to replace a physician or healthcare professional.\r\n\r\nSo, what can we help you today?",
        true,
        {
          type: "radio",
          keepIt: false,
          values: [
            {
              title: "General Question",
              value: "question",
            },
            {
              title: "Search Conditions",
              value: "search",
            },
          ],
        }
      );
    }
  }, []);

  const ask = (message) => {
    setTyping(true);
    api(`chats/ask/${action}`, "post", {
      message,
      _conditionId,
    })
      .then((text) => {
        sendDoctorReply(text, false);
      })
      .finally(() => {
        setTyping(false);
      });
  };

  const sendDoctorReply = (text, delay = true, quickReplies) => {
    setTyping(true);
    setTimeout(
      () => {
        addMessage([
          {
            _id: uuidv4(),
            text,
            createdAt: new Date(),
            user: {
              _id: 2,
              name: "Dr. Hippo",
              avatar: require("../../assets/hippo_avatar.png"),
            },
            quickReplies,
          },
        ]);
        setTyping(false);

        setTimeout(() => {
          console.log(chatRef.current.focus());
        }, 100);
      },
      delay ? _.random(500, 2000) : 0
    );
  };

  useEffect(() => {
    //console.log(_.first(messages))
    //console.log(chatRef.current)
  }, [messages]);

  const onQuickReply = (replies) => {
    const { value, title } = _.get(replies, "0", {});
    setAction(value);
    addMessage([
      {
        _id: uuidv4(),
        text: title,
        createdAt: new Date(),
        user: {
          _id: 1,
        },
      },
    ]);

    if (value === "search") {
      sendDoctorReply(
        "Try to tell me how do you feel and where do you feel discomfort."
      );
    } else if (value === "question") {
      sendDoctorReply("Try to ask me anything about your health.");
    }
  };

  const addMessage = useCallback((messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
  }, []);

  const onSend = useCallback(
    (messages = []) => {
      ask(messages[0].text);
      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, messages)
      );
    },
    [action]
  );

  return (
    <GiftedChat
      messages={messages}
      onSend={(messages) => onSend(messages)}
      onQuickReply={onQuickReply}
      quickReplyStyle={{
        backgroundColor: theme.primary,
        paddingHorizontal: 10,
        minHeight: 40,
        // borderStyle: "none",
      }}
      quickReplyTextStyle={{
        color: theme.secondary,
        fontFamily: "Poppins_500Medium",
        fontSize: 14,
      }}
      renderBubble={(props) => {
        return (
          <Bubble
            {...props}
            textStyle={{
              left: {
                fontFamily: "Poppins_400Regular",
              },
              right: {
                fontFamily: "Poppins_400Regular",
              },
            }}
            wrapperStyle={{
              left: {
                padding: 10,
              },
              right: {
                padding: 10,
              },
            }}
          />
        );
      }}
      textInputStyle={
        {
          //display: 'none'
        }
      }
      placeholder={action ? undefined : "Please select a action..."}
      renderFooter={() => {
        if (!typing) {
          return null;
        }
        return (
          <View
            style={{
              height: 40,
              margin: 10,
            }}>
            <View
              style={{
                height: 40,
                width: 200,
                backgroundColor: "white",
                borderRadius: 20,
                flexDirection: "row",
                alignItems: "center",
              }}>
              <View style={{ marginLeft: 10, marginTop: -20 }}>
                <TypingAnimation />
              </View>
              <Text style={{ marginLeft: 35 }}>Dr. Hippo is typing...</Text>
            </View>
          </View>
        );
      }}
      renderInputToolbar={(props) => {
        return (
          <InputToolbar
            {...props}
            textInputProps={{
              ref: chatRef,
              disabled: !action || typing,
              onKeyPress: (e) => {
                if (e.nativeEvent.key === "Enter") {
                  if (props.text && props.onSend) {
                    let text = props.text;
                    props.onSend({ text: text.trim() }, true);
                  }
                }
              },
            }}
          />
        );
      }}
      user={{
        _id: 1,
      }}
    />
  );
}

export default redux(["details"], ["mergeDetails"])(ChatScreen);
