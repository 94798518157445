import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  View,
  ImageBackground,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Dimensions,
  Platform,
} from "react-native";
import { redux, api, theme } from "../helper";
import _ from "lodash";
import Body from "../Components/Body";
import StepsBar from "../Components/StepsBar";
import { Entypo, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import globalStyles from "../../styles/global.css";

const windowDimensions = Dimensions.get("window");

const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function SymptomsScreen(props) {
  const scrollRef = useRef();

  const { details, navigation, mergeDetails, Translate } = props;

  const [search, setSearch] = useState("");
  const [symptoms, setSymptoms] = useState([]);
  const [area, setArea] = useState("");
  const [side, setSide] = useState("front");

  useEffect(() => {
    //console.log(details)
    if (area !== "") {
      api("symptoms", "get", {
        area,
        gender: details.gender,
      }).then((res) => {
        setSymptoms(res.result);
        scrollRef.current.scrollTo({ x: 0, y: 600, animated: true });
      });
    }
  }, [area]);

  useEffect(() => {
    if (search !== "") {
      setArea("");
      api("symptoms/search", "get", {
        search,
      }).then((res) => {
        setSymptoms(res.result);
        scrollRef.current.scrollTo({ x: 0, y: 600, animated: true });
      });
    }
  }, [search]);

  const onPress = (symptom) => {
    mergeDetails("symptom", symptom);
    navigation.push("duration");
  };

  return (
    <ScrollView
      ref={scrollRef}
      style={{ flex: 1, padding: 20 }}
      contentContainerStyle={{
        //alignItems: 'center',
        paddingBottom: 100,
      }}>
      <StepsBar step={4} />
      <Text
        style={[
          globalStyles.fontLG,
          globalStyles.font_FamilyBold,
          {
            textAlign: "center",
            marginTop: 20,
          },
        ]}>
        {Translate.getStatic("select_symptoms")}
      </Text>
      <View style={{ alignItems: "center" }}>
        <Body
          area={area}
          setArea={setArea}
          side={side}
          componentHeight={componentHeight}
          componentWidth={componentWidth}
        />
        <TouchableOpacity
          onPress={() => setSide(side === "front" ? "back" : "front")}
          style={{
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: theme.primary,
            padding: Platform.OS === "web" ? 10 : componentHeight * 0.024,
            paddingHorizontal:
              Platform.OS === "web" ? 20 : componentHeight * 0.048,
            borderRadius: 20,
          }}>
          <MaterialCommunityIcons
            name="axis-z-rotate-clockwise"
            size={Platform.OS === "web" ? 24 : componentWidth * 0.1}
            color="white"
          />
          <Text
            style={[
              globalStyles.fontSM,
              {
                fontWeight: "bold",
                color: "white",
                marginLeft: 10,
              },
            ]}>
            {Translate.getStatic(
              side === "front" ? "rotate_front" : "rotate_back"
            )}
          </Text>
        </TouchableOpacity>
        <View
          style={{
            padding: Platform.OS === "web" ? 10 : componentHeight * 0.02,
            paddingHorizontal:
              Platform.OS === "web" ? 15 : componentHeight * 0.03,
            backgroundColor: "white",
            borderRadius: 15,
            margin: 15,
          }}>
          <Text
            style={[
              globalStyles.fontSM,
              {
                fontWeight: "bold",
              },
            ]}>
            {Translate.getStatic(area, _.startCase(area)) ||
              Translate.getStatic("body_part")}
          </Text>
        </View>

        <View
          style={{
            backgroundColor: "white",
            paddingHorizontal:
              Platform.OS === "web" ? 15 : componentHeight * 0.03,
            borderRadius: 15,
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 15,
          }}>
          <TextInput
            style={[
              globalStyles.fontSM,
              globalStyles.font_FamilyRegular,
              {
                flex: 1,
                height: Platform.OS === "web" ? 50 : componentHeight * 0.125,
              },
            ]}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`${Translate.getStatic("search")}...`}
          />
          <Feather
            name={"search"}
            size={Platform.OS === "web" ? 24 : componentWidth * 0.1}
            color={theme.primary}
          />
        </View>
        <View
          style={{
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {_.map(symptoms, (symptom) => {
            Translate.load(symptom);
            return (
              <TouchableOpacity
                key={symptom._id}
                onPress={() => {
                  onPress(symptom);
                }}
                style={{
                  maxWidth: Platform.OS === "web" ? 300 : componentHeight * 0.6,
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: theme.primary,
                  padding: Platform.OS === "web" ? 10 : componentHeight * 0.012,
                  borderRadius: 20,
                  marginRight: 5,
                  marginTop: 5,
                }}>
                <Text
                  style={[
                    globalStyles.fontMD,
                    globalStyles.font_FamilyRegular,
                    {
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      marginLeft: 5,
                      marginRight: 5,
                    },
                  ]}>
                  {Translate.get("name")}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </ScrollView>
  );
}

export default redux(["details"], ["mergeDetails"])(SymptomsScreen);
