import React, { useEffect, useState, useRef } from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './src/Screens/Home';
import KnownIllnessScreen from './src/Screens/KnownIllness';
import SymptomsScreen from './src/Screens/Symptoms';
import DurationSeverityScreen from './src/Screens/DurationSeverity';
import PresentationsScreen from './src/Screens/Presentations';
import ConditionsScreen from './src/Screens/Conditions';
import BasicScreen from './src/Screens/Basic';
import TriageScreen from './src/Screens/Triage';
import TriageDescriptionScreen from './src/Screens/TriageDescription';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api, redux, theme, Translation } from './src/helper';
import RedFlagScreen from './src/Screens/RedFlag';
import SurveyScreen from './src/Screens/Survey';
import _ from 'lodash';
import {
	Platform,
	View,
	Text,
	ActivityIndicator,
	ImageBackground,
	TouchableOpacity,
	Image,
} from 'react-native';
import Chat from './src/Screens/Chat';
import Chat2 from './src/Screens/Chat2';
import OCRScreen from './src/Screens/OCR';
import LabAnalysis from './src/Screens/LabAnalysis';
import QRCodeScreen from './src/Screens/QRCode';
import VitalsScreen from './src/Screens/Vitals';
import LoginScreen from './src/Screens/Login';
import TermsScreen from './src/Screens/Terms';
import ProfileScreen from './src/Screens/Profile';
import ChangePasswordScreen from './src/Screens/ChangePassword';
import HealthDetailsScreen from './src/Screens/HealthDetails';
import PreviousReports from './src/Screens/PreviousReports';
import globalStyles from './styles/global.css';
import { onAuthStateChanged, signOut, updateProfile } from 'firebase/auth';
import { auth } from './src/firebase';
import {
	createNavigationContainerRef,
	StackActions,
} from '@react-navigation/native';
import { setActivity, setCurrentUser } from './redux/actions';
import { createDrawerNavigator } from '@react-navigation/drawer';
import {
	FontAwesome,
	MaterialCommunityIcons,
	FontAwesome5,
	MaterialIcons,
} from '@expo/vector-icons';
import divider from 'react-native-paper/src/components/Divider';
import moment from 'moment';
import OrganizationScreen from './src/Screens/Organization';
import TeleconsultationScreen from './src/Screens/Teleconsultation';
import TeleconsultationDetailsScreen from './src/Screens/TeleconsultationDetails';
import { useTimer } from './src/Components/TimerProvider';


const Stack = createNativeStackNavigator();

const linking = {
	prefixes: [],
	config: {},
};

const defaultHeaderStyles = {
	headerStyle: {
		backgroundColor: theme.backgroundColor,
	},
	headerTintColor: theme.secondary,
	headerTitleStyle: {
		fontWeight: 'bold',
		color: theme.secondary,
	},
	headerTitleAlign: 'center',
};

function Container({
	setLanguage,
	language,
	loading,
	setCurrentUser,
	setLoading,
	setDetails,
}) {
	const authRef = useRef(true);
	const navigationRef = useRef();
	const [languages, setLanguages] = useState({});
	// const [activity, setActivity] = useState();
	useEffect(() => {
		AsyncStorage.getItem('language').then(language => {
			if (language) {
				setLanguage(language);
			}
		});

		api('settings/languages').then(res => {
			const languages = _.get(res, 'result.setting', {});
			AsyncStorage.setItem('languages', JSON.stringify(languages));
			setLanguages(languages);
		});

		AsyncStorage.getItem('languages').then(languages => {
			if (languages) {
				setLanguages(JSON.parse(languages));
			}
		});
	}, []);

	const goResult = _resultId => {
		setLoading(true);
		api(`results/${_resultId}`)
			.then(res => {
				const { conditions, details } = res.result.result;
				setDetails(details);
				navigationRef.current.dispatch(
					StackActions.push('conditions', { conditions, _resultId })
				);
			})
			.catch(e => {
				alert(e.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onReady = () => {
		if (Platform.OS === 'web') {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);

			if (urlParams.get('chat') === '') {
				authRef.current = false;
				navigationRef.current.dispatch(StackActions.replace('chat'));
				/*navigation.push("chat", {
		  _conditionId: urlParams.get("_conditionId"),
		  conditionName: urlParams.get("conditionName"),
		});*/
			}
			if (urlParams.get('lab') === '') {
				authRef.current = false;
				navigationRef.current.dispatch(StackActions.replace('ocr'));
			}
			if (urlParams.get('sc') === '') {
				authRef.current = false;
				navigationRef.current.dispatch(StackActions.replace('home_container'));
			}
			if (urlParams.get('_resultId')) {
				authRef.current = false;
				goResult(urlParams.get('_resultId'));
			}
		}

		const unsub = onAuthStateChanged(auth, async user => {
			if (authRef.current === false) {
				return;
			}
			if (user) {
				const profile = await api(`users/profile`).then(res => res.result);
				setCurrentUser(profile);
				if (profile._id) {
					const storedActivity = await AsyncStorage.getItem('userActivity');
					if (!storedActivity) {
						const activity = await api('user-activities', 'post', {
							_userId: profile._id,
							paymentMethod: 'Corporate Billing',
						});
						AsyncStorage.setItem('userActivity', JSON.stringify(activity.result));
					}
				}

				const details = {};
				if (profile.gender) {
					_.set(details, 'gender', profile.gender);
				}
				if (profile.dob) {
					const year = parseInt(profile.dob.split('/')[2]);
					const age = parseInt(moment().format('YYYY')) - year;
					_.set(details, 'age', age);
				}
				if (profile.knownIllnesses) {
					_.set(details, 'knownIllnesses', profile.knownIllnesses);
				}
				if (profile.familyIllnesses) {
					_.set(details, 'familyIllnesses', profile.familyIllnesses);
				}
				setDetails(details);
				navigationRef.current.dispatch(StackActions.replace('home_container'));
			} else {
				AsyncStorage.removeItem('userActivity');
				setCurrentUser(false);

				navigationRef.current.dispatch(StackActions.replace('login'));
			}
		});
		return () => unsub();
	};
	useEffect(() => {
		onReady();
	}, []);
	const routes = [
		{ title: 'Home', name: 'home_container', Component: HomeContainer },
		{ title: 'Personal Details', name: 'basic', Component: BasicScreen },
		{
			title: 'Underlying Condition(s)',
			name: 'known_illnesses',
			Component: KnownIllnessScreen,
		},
		{
			title: 'Family or Inheritable Condition(s)',
			name: 'family_illnesses',
			Component: KnownIllnessScreen,
		},
		{ title: 'Symptom Selection', name: 'symptoms', Component: SymptomsScreen },
		{
			title: 'Duration / Onset of symptom',
			name: 'duration',
			Component: DurationSeverityScreen,
		},
		{
			title: 'Severity of your symptom(s)',
			name: 'severity',
			Component: DurationSeverityScreen,
		},
		{ title: 'Pain Score', name: 'pain', Component: DurationSeverityScreen },
		{
			title: 'QR Code',
			name: 'qr_code',
			Component: Platform.OS === 'web' ? <div /> : QRCodeScreen,
		},
		{ title: 'Vitals', name: 'vitals', Component: VitalsScreen },
		{
			title: 'Presentations',
			name: 'presentations',
			Component: PresentationsScreen,
		},
		{ title: 'Conditions', name: 'conditions', Component: ConditionsScreen },
		{ title: 'Patient Education', name: 'triage', Component: TriageScreen },
		{
			title: 'Patient Education',
			name: 'triage_description',
			Component: TriageDescriptionScreen,
		},
		{ title: 'Red Flag', name: 'red_flag', Component: RedFlagScreen },
		{
			title: 'Teleconsultation',
			name: 'teleconsultation',
			Component: TeleconsultationScreen,
		},
		{
			title: 'Teleconsultation Details',
			name: 'teleconsultation_details',
			Component: TeleconsultationDetailsScreen,
		},

		{ title: 'Survey', name: 'survey', Component: SurveyScreen },
		{ title: 'Dr. Hippo', name: 'chat', Component: Chat },
		{ title: 'STARBOX', name: 'chat2', Component: Chat2 },
		{ title: 'Lab AI', name: 'ocr', Component: OCRScreen },
		{ title: 'Lab Analysis', name: 'lab_analysis', Component: LabAnalysis },
		{ title: 'Login', name: 'login', Component: LoginScreen },
	];

	if (_.isEmpty(languages)) {
		return null;
	}

	const MyTheme = {
		...DefaultTheme,
		colors: {
			...DefaultTheme.colors,
			primary: theme.primary,
			secondary: theme.secondary,
			background: theme.backgroundColor,
		},
	};

	return (
		<View
			style={{
				flex: 1,
				backgroundColor: 'black',
				//alignItems: "center",
				//justifyContent: "center",
				flexDirection: 'row',
			}}
		>
			<View
				style={{
					backgroundColor: 'black',
					flex: 1,
					zIndex: 999,
				}}
			/>
			<View
				style={{
					maxWidth: Platform.OS === 'web' ? 500 : undefined,
					minWidth: 300,
					width: '100%',
				}}
			>
				{loading && (
					<View
						style={{
							position: 'fixed',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: 'rgba(0,0,0,0.6)',
							zIndex: 1000000,
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<ActivityIndicator size={'large'} color={'white'} />
						<Text
							style={[
								globalStyles.fontMD,
								globalStyles.font_FamilyRegular,
								{
									color: 'white',
									marginTop: 10,
								},
							]}
						>
							Loading...
						</Text>
					</View>
				)}
				<NavigationContainer
					theme={MyTheme}
					ref={navigationRef}
					onReady={onReady}
				>
					<Stack.Navigator
						initialRouteName={'login'}
					//initialRouteName={"home"}
					>
						{_.map(routes, ({ title, name, Component }) => {
							const languagesProp = _.get(languages, name, {});
							const Translate = new Translation(language, languagesProp);
							// console.log(Translate);
							return (
								<Stack.Screen
									key={name}
									name={name}
									options={{
										title: Translate.getStatic('title', title),
										...defaultHeaderStyles,
									}}
								>
									{props => (
										<Component
											{...props}
											language={language}
											languages={languages}
											Translate={Translate}
										/>
									)}
								</Stack.Screen>
							);
						})}
					</Stack.Navigator>
				</NavigationContainer>
			</View>
			<View
				style={{
					backgroundColor: 'black',
					flex: 1,
					zIndex: 999,
				}}
			/>
		</View>
	);
}

const Drawer = createDrawerNavigator();

const DrawerContent = redux(['user'])(
	({ state, descriptors, user, navigation }) => {
		const photoUrl = _.get(user, 'photoUrl');
		const { resetTimer } = useTimer();
		return (
			<View
				style={{
					backgroundColor: theme.backgroundColor,
					flex: 1,
				}}
			>
				<TouchableOpacity
					onPress={() => {
						navigation.navigate('profile');
					}}
				>
					<ImageBackground
						style={{
							height: 90,
							alignItems: 'center',
							paddingHorizontal: 15,
							flexDirection: 'row',
							marginBottom: 10,
						}}
						source={require('./assets/profile-bg.png')}
						resizeMode={'cover'}
					>
						<Image
							style={{
								width: 60,
								height: 60,
								backgroundColor: 'white',
								borderRadius: 30,
								marginRight: 10,
							}}
							source={
								photoUrl
									? { uri: photoUrl }
									: require('./assets/default-profile.jpg')
							}
							resizeMode={'cover'}
						/>
						<View>
							<Text
								style={[globalStyles.font_FamilySemiBold, { color: 'white' }]}
							>
								{user?.displayName}
							</Text>
							<Text
								style={[
									globalStyles.font_FamilyRegular,
									{
										color: 'white',
										marginTop: -5,
									},
								]}
							>
								{user?.email}
							</Text>
							{user.organization && (
								<Text
									style={[
										globalStyles.font_FamilyRegular,
										{
											color: 'white',
											marginTop: -5,
										},
									]}
								>
									{user?.organization._organizationId.name}
								</Text>
							)}
						</View>
					</ImageBackground>
				</TouchableOpacity>

				<View style={{ flex: 1 }}>
					{_.map(state.routes, (item, index) => {
						const selected = state.index === index;
						const options = _.get(descriptors, `${item.key}.options`);
						const Icon = options.drawerIcon;
						return (
							<View key={item.key}>
								<TouchableOpacity
									style={{
										height: 50,
										alignItems: 'center',
										backgroundColor: selected ? '#befffd' : null,
										paddingHorizontal: 15,
										flexDirection: 'row',
									}}
									onPress={() => navigation.navigate(item.name)}
								>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<Icon size={25} color={theme.primary} />
									</View>
									<Text
										style={[
											{
												fontSize: 16,
												color: theme.primary,
												marginLeft: 10,
											},
											globalStyles.font_FamilyMedium,
										]}
									>
										{options.title}
									</Text>
								</TouchableOpacity>
								{options?.divider && (
									<View
										style={{
											borderBottomWidth: 0.5,
											borderColor: theme.primary,
											marginVertical: 5,
										}}
									/>
								)}
							</View>
						);
					})}
				</View>
				<View>
					<TouchableOpacity
						style={{
							height: 50,
							alignItems: 'center',
							paddingHorizontal: 15,
							flexDirection: 'row',
							marginBottom: 15,
						}}
						onPress={async () => {
							await AsyncStorage.removeItem('userActivity');
							resetTimer();
							// await AsyncStorage.removeItem('startTime');
							signOut(auth);
						}}
					>
						<View
							style={{
								width: 30,
								height: 30,
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<MaterialIcons size={24} color={'red'} name={'logout'} />
						</View>
						<Text
							style={[
								{
									fontSize: 16,
									color: 'red',
									marginLeft: 10,
								},
								globalStyles.font_FamilyMedium,
							]}
						>
							Logout
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
);
const HomeContainer = ({ navigation, languages, language }) => {
	useEffect(() => {
		navigation.setOptions({
			headerShown: false,
		});
	}, []);

	const routes = [
		{
			name: 'home',
			title: 'A.I Symptoms Checker',
			Icon: MaterialCommunityIcons,
			Component: HomeScreen,
			iconProps: {
				name: 'clipboard-check-outline',
			},
		},
		/*{
	  name: 'basic',
	  title: "A.I Symptoms Checker",
	  Icon: MaterialCommunityIcons,
	  Component: BasicScreen,
	  iconProps: {
		name: 'clipboard-check-outline'
	  }
	},*/
		{
			name: 'lab',
			title: 'A.I Lab',
			Icon: MaterialCommunityIcons,
			Component: OCRScreen,
			iconProps: {
				name: 'microscope',
			},
		},
		{
			name: 'previous_reports',
			title: 'Previous Reports',
			Icon: MaterialCommunityIcons,
			Component: PreviousReports,
			iconProps: {
				name: 'file-document-multiple-outline',
			},
			divider: true,
		},
		{
			name: 'profile',
			title: 'User Profile',
			Icon: FontAwesome,
			Component: ProfileScreen,
			iconProps: {
				name: 'user',
			},
		},
		{
			name: 'organization',
			title: 'Organization',
			Icon: FontAwesome,
			Component: OrganizationScreen,
			iconProps: {
				name: 'building',
			},
		},
		{
			name: 'health_details',
			title: 'Edit Health Details',
			Icon: FontAwesome5,
			Component: HealthDetailsScreen,
			iconProps: {
				name: 'heartbeat',
			},
		},
		{
			name: 'change_password',
			title: 'Change Password',
			Icon: FontAwesome5,
			Component: ChangePasswordScreen,
			iconProps: {
				name: 'lock',
			},
			divider: true,
		},
		{
			name: 'terms',
			title: 'Terms & Conditions',
			Icon: MaterialCommunityIcons,
			Component: TermsScreen,
			iconProps: {
				name: 'file-document-multiple',
			},
		},
		{
			name: 'privacy',
			title: 'Privacy Policies',
			Icon: MaterialCommunityIcons,
			Component: TermsScreen,
			iconProps: {
				name: 'file-document-multiple',
			},
		},
	];

	return (
		<Drawer.Navigator
			initialRouteName='home'
			useLegacyImplementation={true}
			//defaultStatus={'open'}
			screenOptions={{
				...defaultHeaderStyles,
			}}
			drawerContent={props => {
				//console.log(props)
				return <DrawerContent {...props} />;
			}}
		>
			{_.map(routes, props => {
				const { name, title, Icon, iconProps, Component, divider } = props;
				return (
					<Drawer.Screen
						key={name}
						name={name}
						options={{
							title,
							drawerIcon: props => <Icon {...iconProps} {...props} />,
							divider,
						}}
					>
						{props => {
							const languagesProp = _.get(languages, name, {});
							const Translate = new Translation(language, languagesProp);
							return <Component {...props} Translate={Translate} />;
						}}
					</Drawer.Screen>
				);
			})}
		</Drawer.Navigator>
	);
};

export default redux(
	['language', 'loading'],
	['setLanguage', 'setCurrentUser', 'setLoading', 'setDetails']
)(Container);
