import {Text, View, Platform, TextInput} from "react-native";
import globalStyles from "../../styles/global.css";
import {useState} from "react";
import Button from "../Components/Button";
import {api, redux} from "../helper";
import {signOut} from "firebase/auth";
import {auth} from "../firebase";
const ChangePassword = ({setLoading}) => {

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const save = async () => {
    try {
      setLoading(true)
      await api(`users/password`, 'put',{password})
      alert("Password changed successfully! Please login again.")
      await signOut(auth)
    } catch (e) {
      alert(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <View style={{ flex: 1, padding: 10 }}>
      <View
        style={{
          backgroundColor: 'white',
          padding: 15,
          alignItems: 'center',
          borderRadius: 15
        }}
      >
        <View
          style={{
            width: "100%"
          }}
        >
          <Text
            style={[
              globalStyles.font_FamilySemiBold,
              {
                marginBottom: 10
              }
            ]}
          >
            New Password
          </Text>
          <TextInput
            style={[
              styles.inputStyle
            ]}
            value={password}
            secureTextEntry
            onChangeText={setPassword}
            placeholder={'******'}
          />
        </View>
        <View
          style={{
            marginTop: 10,
            width: "100%"
          }}
        >
          <Text
            style={[
              globalStyles.font_FamilySemiBold,
              {
                marginBottom: 10
              }
            ]}
          >
            New Confirm Password
          </Text>
          <TextInput
            style={[
              styles.inputStyle
            ]}
            value={confirmPassword}
            secureTextEntry
            onChangeText={setConfirmPassword}
            placeholder={'******'}
          />
        </View>
        <Button
          style={{
            marginTop: 15
          }}
          onPress={save}
        >
          Save
        </Button>
      </View>
    </View>
  );
};

const styles = {
  inputStyle: {
    height: 40,
    backgroundColor: '#eee',
    paddingHorizontal: 10,
    borderRadius: 10,
    alignItems: 'center',
    flexDirection: 'row'
  }
};
export default redux(['user'], ['setLoading'])(ChangePassword);