import {
  Modal,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Dimensions,
  Platform,
  FlatList,
} from "react-native";
import { Entypo, Feather, MaterialIcons } from "@expo/vector-icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { theme, redux } from "../helper";
import globalStyles from "../../styles/global.css";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

const SelectionModal = (props) => {
  const { setModal, modal, options, onSelected, selection, Translate } = props;
  const { title, path, filter } = modal || {};

  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch("");
  }, [modal]);

  let filtered = options;

  if (filter) {
    filtered = _.filter(options, filter);
  }

  if (search !== "") {
    const out = [];
    _.map(filtered, (option) => {
      let name = option?.name;
      if (_.includes(_.lowerCase(name), _.lowerCase(search))) {
        out.push(option);
      }
    });
    filtered = out;
  }

  return (
    <Modal
      animationType="slide"
      //transparent={true}
      visible={!!modal}
      onRequestClose={() => {
        setModal(false);
      }}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            height: Platform.OS === "web" ? 60 : componentHeight * 0.15,
            alignItems: "center",
            paddingHorizontal:
              Platform.OS === "web" ? 20 : componentHeight * 0.048,
            flexDirection: "row",
          }}
        >
          <View style={{ width: 24, height: 24 }} />
          <Text
            style={[
              globalStyles.fontMD,
              globalStyles.font_FamilyBold,
              {
                flex: 1,
                fontWeight: "bold",
                textAlign: "center",
                color: theme.secondary,
              },
            ]}
          >
            {Translate.getStatic("search")} {title}
          </Text>
          <TouchableOpacity onPress={() => setModal(false)}>
            <Entypo
              name="cross"
              size={Platform.OS === "web" ? 24 : componentWidth * 0.1}
              color="black"
            />
          </TouchableOpacity>
        </View>
        <View style={{ margin: 20, marginTop: 0 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: Platform.OS === "web" ? 40 : componentHeight * 0.096,
              backgroundColor: "#e8e8e8",
              borderRadius: 20,
              paddingHorizontal:
                Platform.OS === "web" ? 20 : componentHeight * 0.048,
            }}
          >
            <TextInput
              style={[
                globalStyles.fontSM,
                globalStyles.font_FamilyRegular,
                {
                  flex: 1,
                  height: Platform.OS === "web" ? 40 : componentHeight * 0.096,
                },
              ]}
              placeholder={`${Translate.getStatic("search")}...`}
              onChangeText={setSearch}
            />
            <Feather
              name="search"
              size={Platform.OS === "web" ? 20 : componentWidth * 0.08}
              color="black"
            />
          </View>
        </View>
        <FlatList
          data={filtered}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() => {
                onSelected(item, path);
                setModal(false);
              }}
              key={item._id || item.value}
              style={{
                height: Platform.OS === "web" ? 60 : componentHeight * 0.15,
                borderTopWidth: index === 0 ? 1 : 0,
                borderBottomWidth: 1,
                alignItems: "center",
                paddingHorizontal:
                  Platform.OS === "web" ? 20 : componentHeight * 0.048,
                borderColor: "#e3e3e3",
                flexDirection: "row",
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={[
                    globalStyles.fontSM,
                    globalStyles.font_FamilySemiBold,
                  ]}
                >
                  {Translate.get("name", item.name)}
                </Text>
              </View>
              {selection !== false && (
                <MaterialIcons
                  name="add-circle"
                  size={Platform.OS === "web" ? 20 : componentWidth * 0.08}
                  color="black"
                />
              )}
            </TouchableOpacity>
          )}
        />
        {/* <ScrollView style={{ flex: 1 }}>
          {_.map(filtered, (item, index) => {
            Translate.load(item);
            return (
              <TouchableOpacity
                onPress={() => {
                  onSelected(item, path);
                  setModal(false);
                }}
                key={item._id || item.value}
                style={{
                  height: Platform.OS === "web" ? 60 : componentHeight * 0.15,
                  borderTopWidth: index === 0 ? 1 : 0,
                  borderBottomWidth: 1,
                  alignItems: "center",
                  paddingHorizontal:
                    Platform.OS === "web" ? 20 : componentHeight * 0.048,
                  borderColor: "#e3e3e3",
                  flexDirection: "row",
                }}
              >
                <View style={{ flex: 1 }}>
                  <Text
                    style={[
                      globalStyles.fontSM,
                      globalStyles.font_FamilySemiBold,
                    ]}
                  >
                    {Translate.get("name", item.name)}
                  </Text>
                </View>
                {selection !== false && (
                  <MaterialIcons
                    name="add-circle"
                    size={Platform.OS === "web" ? 20 : componentWidth * 0.08}
                    color="black"
                  />
                )}
              </TouchableOpacity>
            );
          })}
        </ScrollView> */}
      </View>
    </Modal>
  );
};

export default SelectionModal;
