import React, {useEffect, useState} from "react";
import {
  Image,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Dimensions,
  Platform,
} from "react-native";
import {api, baseUrl, redux, theme} from "../helper";
import {Slider, Switch} from "@rneui/themed";
import _ from "lodash";
import Button from "../Components/Button";
import validator from "validator";
import {Foundation} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as DocumentPicker from "expo-document-picker";
import globalStyles from "../../styles/global.css";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function OCRScreen(props) {
  const {navigation} = props;

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(false);
  const [resultId, setResultId] = useState(false);
  const [scenarios, setScenarios] = useState(false);
  const [prev, setPrev] = useState(false);

  console.log(scenarios)

  const scan = (file) => {
    const formData = new FormData();
    formData.append("_companyId", company?._id || "633311072f7aec5af855a774");
    formData.append("file", file);
    api(`extract/upload/scenarios`, "post", formData, setLoading)
      .then((res) => {
        //console.log(res.result)
        //console.log(res.result)
        setResultId(_.get(res, 'result._resultId'))
        setScenarios(_.get(res, 'result.scenarios'));
      })
      .catch((e) => alert(e.message));
  };

  useEffect(() => {
    //scan()

    const {scenarios, resultId} = _.get(props, 'route.params', {})

    if (scenarios) {
      setScenarios(scenarios)
      setResultId(resultId)
      setPrev(true)
    }

    api(`blood-test/companies`).then((res) => {
      setCompanies([_.first(res.result)]);
    });
  }, []);

  const pickPDF = async () => {
    const picker = await DocumentPicker.getDocumentAsync({
      type: "application/pdf",
    });

    scan(picker.file);
  };

  let output = null;

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Text>Loading...</Text>
      </View>
    );
  }

  if (scenarios) {
    output = (
      <View>
        {/*     <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: 20, marginBottom: 10}}>
          Lab Analysis
        </Text>*/}
        {_.map(scenarios, (scenario, index) => {
          return (
            <TouchableOpacity
              onPress={() => {
                navigation.push("lab_analysis", {
                  _scenarioId: scenario._id,
                });
              }}
              key={scenario._id}
              style={{
                padding: 15,
                backgroundColor: "white",
                borderRadius: 10,
                marginBottom: 10,
              }}>
              <Text style={[globalStyles.fontSM]}>
                {index + 1}. {scenario.fullName}
              </Text>
            </TouchableOpacity>
          );
        })}
        {!prev &&
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              marginTop: 15
            }}
          >
            <Button
              onPress={() => {
                setCompany(false)
                setScenarios(false)
              }}
            >
              Upload New Report
            </Button>
          </View>
        }

        <View
          style={{
            flex: 1,
            alignItems: 'center',
            marginTop: 15
          }}
        >
          <Button
            onPress={() => {
              window.open(`${baseUrl}/api/results/${resultId}/lab/pdf`, '_blank');
            }}
          >
            Download PDF Report
          </Button>
        </View>
      </View>
    );
  } else if (!company) {
    output = (
      <View
        style={{
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "center",
        }}>
        {_.map(companies, (company) => {
          return (
            <TouchableOpacity
              onPress={() => setCompany(company)}
              key={company?._id}
              style={{
                margin: 10,
                borderRadius: 10,
                overflow: "hidden",
              }}>
              <Image
                source={{uri: company?.logoUrl}}
                style={{
                  width: Platform.OS === "web" ? 150 : componentHeight * 0.3,
                  height: Platform.OS === "web" ? 150 : componentHeight * 0.3,
                }}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    );
  } else if (company) {
    output = (
      <TouchableOpacity onPress={pickPDF} style={{alignItems: "center"}}>
        <Text
          style={[
            globalStyles.fontXL,
            {
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 10,
            },
          ]}>
          {company?.name}
        </Text>
        <View
          style={{
            width: Platform.OS === "web" ? 200 : componentHeight * 0.4,
            height: Platform.OS === "web" ? 200 : componentHeight * 0.4,
            borderWidth: 1,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
          }}>
          <Foundation
            name="upload-cloud"
            size={Platform.OS === "web" ? 50 : componentWidth * 0.2}
            color="black"
          />
          <Text
            style={[
              globalStyles.fontSM,
              {
                textAlign: "center",
              },
            ]}>
            Upload PDF
          </Text>
        </View>
      </TouchableOpacity>
    );
  }

  return <ScrollView style={{flex: 1, padding: 15}}>{output}</ScrollView>;
}

export default redux(["details"], ["mergeDetails"])(OCRScreen);
