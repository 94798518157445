import {ScrollView, View, Text, TouchableOpacity} from "react-native";
import Markdown from "react-native-markdown-renderer";
import React, { useEffect, useState } from "react";
import {api, theme} from "../helper";
import _ from "lodash";
import globalStyles from "../../styles/global.css";
import { AntDesign, Entypo } from '@expo/vector-icons';
import ReactMarkdown from 'react-markdown'

function LabAnalysis({ route, navigation }) {
  const [scenario, setScenario] = useState({});
  const [faqSelected, setFaqSelected] = useState(false);

  useEffect(() => {
    //6496a11ce72b4b6b6a2c363a
    const _scenarioId = _.get(route, "params._scenarioId");
    api(`blood-test/scenarios/${_scenarioId}`).then((res) => {
      const scenario = res.result;
      navigation.setOptions({
        headerTitle: scenario.fullName,
      });
      setScenario(scenario);
    });
  }, []);

  let faqArray = scenario?.faq?.replace(/\n/g, '').split(`\`\`\``)
  _.pullAt(faqArray, 0)
  faqArray = _.chunk(faqArray, 2)
  //console.log(faqArray)
  return (
    <ScrollView
      style={{
        backgroundColor: "white",
        height: "100%",
        borderRadius: 10,
        marginTop: 10,
        padding: 20,
        //marginBottom: 20,
        paddingVertical: 10,
        margin: 15,
      }}
    >
      <Markdown
        style={{
          text: globalStyles.font_FamilyRegular,
          listUnorderedItem: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            //alignItems: 'center'
          },
          listUnorderedItemIcon: {
            fontSize: 50,
            marginHorizontal: 10,
            lineHeight: 32,
          },
          strong: globalStyles.font_FamilySemiBold,
        }}
      >
        {'\n\n'}**Diagnosis**{'\n\n'}
        {scenario.diagnosis}
        {'\n\n'}**POSSIBLE CAUSES**{'\n\n'}
        {scenario.causes}
        {'\n\n'}**FAQ**{'\n\n'}
      </Markdown>
      <View style={{marginTop: 10}}>
        {_.map(faqArray, (item, index) => {
          const selected = faqSelected === index
          return (
            <View
              key={`faq-${index}`}
              style={{
                marginBottom: 10,
                borderRadius: 5,
                overflow: 'hidden'
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  if(index === faqSelected) {
                    setFaqSelected(false)
                  } else {
                    setFaqSelected(index)
                  }
                }}
                style={{
                  backgroundColor: theme.primary,
                  alignItems: 'center',
                  paddingHorizontal: 15,
                  paddingVertical: 10,
                  flexDirection: 'row'
                }}
              >
                <Text
                  style={[
                    globalStyles.font_FamilySemiBold,
                    {
                      flex: 1,
                      fontSize: 14
                    }
                  ]}
                >
                  {item[0]}
                </Text>
                <Entypo name={`chevron-${selected ? 'up' : 'down'}`} size={24} color="black" />
              </TouchableOpacity>
              {selected &&
                <View
                  style={{
                    backgroundColor: theme.backgroundColor,
                    padding: 10
                  }}
                >
                  <Text
                    style={[
                      globalStyles.font_FamilyRegular,
                      {
                        flex: 1,
                        fontSize: 14
                      }
                    ]}
                  >
                    {item[1]}
                  </Text>
                </View>
              }
            </View>
          )
        })}
      </View>
    </ScrollView>
  );
}

export default LabAnalysis;
