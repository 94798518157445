import { Text, TouchableOpacity, Dimensions, Platform } from "react-native";
import { theme } from "../helper";
import React from "react";

const windowDimensions = Dimensions.get("window");

const Button = ({
  onPress,
  children,
  style,
  textColor,
  type,
  size,
  disabled,
}) => {
  const componentHeight = windowDimensions.height * 0.5;
  const componentWidth = windowDimensions.width * 0.5;

  let backgroundColor = theme.primary;
  let color = textColor || theme.secondary;
  let height = Platform.OS === "web" ? 60 : componentHeight * 0.12;
  let fontSize = Platform.OS === "web" ? 18 : componentWidth * 0.07;

  if (type === "secondary") {
    backgroundColor = "white";
  }

  if (size === "small") {
    height = Platform.OS === "web" ? 50 : componentHeight * 0.1;
    fontSize = Platform.OS === "web" ? 14 : componentWidth * 0.05;
  }

  return (
    <TouchableOpacity
      onPress={() => !disabled && onPress()}
      style={{
        height,
        width: Platform.OS === "web" ? 250 : componentHeight * 0.6,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: disabled ? "#c4c4c4" : backgroundColor,
        borderRadius: 15,
        ...style,
      }}>
      <Text
        style={{
          top: 3,
          fontFamily: "Poppins_700Bold",
          fontSize,
          color,
        }}>
        {children}
      </Text>
    </TouchableOpacity>
  );
};

export default Button;
