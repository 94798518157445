import {Text, View, Platform, useWindowDimensions, TouchableOpacity, ScrollView, RefreshControl} from "react-native";
import {useEffect, useState} from "react";
import {api, theme, redux} from "../helper";
import {TabView, SceneMap, TabBar} from 'react-native-tab-view';
import globalStyles from "../../styles/global.css";
import {Fontisto, Ionicons} from "@expo/vector-icons";
import moment from "moment";
import {useIsFocused} from "@react-navigation/native";

const SymptomsRoute = ({navigation, setDetails}) => {
  const isFocused = useIsFocused()
  const [results, setResults] = useState([])

  useEffect(() => {
    if(isFocused) {
      api('results/type/conditions').then(res => {
        setResults(res.result)
      })
    }
  }, [isFocused])

  useEffect(() => {

  }, [])

  return (
    <ScrollView>
      {_.map(results, result => {
        return (
          <TouchableOpacity
            onPress={() => {
              const {conditions, details} = result.result;
              //setDetails(details);
              navigation.push('conditions', {conditions, _resultId: result._id})
            }}
            key={result._id}
            style={{
              backgroundColor: 'white',
              flexDirection: 'row',
              margin: 15,
              marginBottom: 0,
              borderRadius: 15,
              padding: 10,
              alignItems: 'center'
            }}
          >
            <Ionicons name="document-text-outline" size={40} color="black" />
            <View
              style={{
                marginLeft: 10,
                flexDirection: 'column'
              }}
            >
              <Text
                style={[
                  globalStyles.font_FamilyMedium,
                ]}
              >
                Symptoms Report
              </Text>
              <Text
                style={[
                  globalStyles.font_FamilyRegular,
                ]}
              >
                {moment(result.createdAt).format('h:mmA - DD/MM/YYYY')}
              </Text>
            </View>
          </TouchableOpacity>
        )
      })}
    </ScrollView>
  )
}

const SecondRoute = ({navigation}) => {
  const isFocused = useIsFocused()
  const [results, setResults] = useState(null)

  useEffect(() => {
    if(isFocused) {
      api('results/type/lab').then(res => {
        setResults(res.result)
      })
    }
  }, [isFocused])

  return (
    <ScrollView>
      {_.map(results, result => {
        return (
          <TouchableOpacity
            onPress={() => {
              const {scenarios} = result.result;
              navigation.push('ocr', {
                scenarios,
                resultId: result._id
              })
            }}
            key={result._id}
            style={{
              backgroundColor: 'white',
              flexDirection: 'row',
              margin: 15,
              marginBottom: 0,
              borderRadius: 15,
              padding: 10,
              alignItems: 'center'
            }}
          >
            <Fontisto name="blood-test" size={40} color="black" />
            <View
              style={{
                marginLeft: 10,
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Text
                style={[
                  globalStyles.font_FamilyMedium,
                ]}
              >
                Lab Report
              </Text>
              <Text
                style={[
                  globalStyles.font_FamilyLight,
                  {
                    fontSize: 12
                  }
                ]}
              >
                {_.get(result, 'result.originalFilename')}
              </Text>
              <Text
                style={[
                  globalStyles.font_FamilyRegular,
                ]}
              >
                {moment(result.createdAt).format('h:mmA - DD/MM/YYYY')}
              </Text>
            </View>
          </TouchableOpacity>
        )
      })}
    </ScrollView>
  )
}

const PreviousReports = (props) => {

  const layout = useWindowDimensions();

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'symptoms', title: 'Symptoms' },
    { key: 'lab', title: 'Lab' },
  ]);

  const renderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: theme.primary }}
      style={{ backgroundColor: theme.secondary }}
      labelStyle={[
        globalStyles.font_FamilySemiBold
      ]}
    />
  );

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={({ route }) => {
        switch (route.key) {
          case 'symptoms':
            return <SymptomsRoute {...props}/>;
          case 'lab':
            return <SecondRoute {...props}/>;
          default:
            return null;
        }}
      }
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
      renderTabBar={renderTabBar}
    />
  );
};

const styles = {};

export default redux([], ['setDetails'])(PreviousReports);
