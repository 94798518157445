import {Text, View, Platform} from "react-native";
import WebView from "react-native-webview";
import React from "react";

const TNC = () => {
  return (
    <View style={{flex: 1}}>
      {Platform.OS === 'web' ?
        <iframe
          src={"https://ai.mediverse.my/disclaimer.html"}
          style={{
            border: 0,
            height: '100%'
          }}
        />
        :
        <WebView
          source={{uri: "https://ai.mediverse.my/disclaimer.html"}}
          style={{
            flex: 1,
            height: '100%',
            //backgroundColor: 'red'
          }}
        />
      }
    </View>
  )
}

const styles = {
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    marginTop: 15
  },
  description: {
    textAlign:'center',
    marginTop: 15
  }
}

export default TNC