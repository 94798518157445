import { StyleSheet, Platform, Dimensions } from "react-native";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

const globalStyles = StyleSheet.create({
  fontXXS: {
    fontSize: Platform.OS === "web" ? 10 : componentWidth * 0.03,
  },
  fontXS: {
    fontSize: Platform.OS === "web" ? 12 : componentWidth * 0.04,
  },
  fontSM: {
    fontSize: Platform.OS === "web" ? 14 : componentWidth * 0.05,
  },
  fontMD: {
    fontSize: Platform.OS === "web" ? 16 : componentWidth * 0.06,
  },
  fontLG: {
    fontSize: Platform.OS === "web" ? 18 : componentWidth * 0.07,
  },
  fontXL: {
    fontSize: Platform.OS === "web" ? 20 : componentWidth * 0.08,
  },
  fontXXL: {
    fontSize: Platform.OS === "web" ? 24 : componentWidth * 0.1,
  },
  fontDoubleXL: {
    fontSize: Platform.OS === "web" ? 40 : componentWidth * 0.16,
  },
  font_FamilyBold: {
    fontFamily: "Poppins_700Bold",
  },
  font_FamilySemiBold: {
    fontFamily: "Poppins_600SemiBold",
  },
  font_FamilyMedium: {
    fontFamily: "Poppins_500Medium",
  },
  font_FamilyRegular: {
    fontFamily: "Poppins_400Regular",
  },
  font_FamilyLight: {
    fontFamily: "Poppins_300Light",
  },
});

export default globalStyles;
