import React, {useEffect, useRef, useState} from "react";
import {
  Text,
  View,
  Image,
  Platform,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Dimensions,
  Linking, TextInput,
} from "react-native";
import {redux, Translation, theme, api} from "../helper";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {AntDesign, Entypo} from "@expo/vector-icons";
import Header from "../Components/Header";
import Button from "../Components/Button";
import TNC from "../Components/TNC";
import WebView from "react-native-webview";
import globalStyles from "../../styles/global.css";
import {auth} from '../firebase'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup
} from 'firebase/auth'


const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function HomeScreen(props) {
  const {
    details,
    navigation,
    Translate,
    setLoading,
    setDetails,
    user
  } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('signIn');

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });

  }, []);


  const signIn = async () => {
    try {
      setLoading(true)
      await signInWithEmailAndPassword(auth, email, password)
      
    } catch (e) {
      alert(e.message)
    } finally {
      setLoading(false)
    }
  }

  const socialLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true)
      await signInWithPopup(auth, provider)
    } catch (e) {
      alert(e.message)
    } finally {
      setLoading(false)
    }
  }

  const signUp = async () => {
    try {
      setLoading(true)
      if (name.length === 0) {
        throw new Error("Name cannot be empty.")
      }
      await createUserWithEmailAndPassword(auth, email, password)
      await updateProfile(auth.currentUser, {
        displayName: name
      })
    } catch (e) {
      alert(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ImageBackground
      style={{
        flex: 1,
        paddingTop: 30,
      }}
      source={require("../../assets/bg.png")}
      resizeMode={"cover"}
    >
      {user !== null && <Header {...props} /> }
      <View
        style={{flex: 1, alignItems: "center", justifyContent: "center"}}
      >
        <Image
          source={require("../../assets/logo.png")}
          style={{
            width: Platform.OS === "web" ? 260 : componentHeight * 0.65,
            height: Platform.OS === "web" ? 70 : componentHeight * 0.18,
            resizeMode: "contain",
          }}
        />
        <Text
          style={[
            globalStyles.fontXS,
            globalStyles.font_FamilyRegular,
            {
              color: "white",
            },
          ]}
        >
          {Translate.getStatic("slogan")}
        </Text>
      </View>

      {user !== null &&
        <View
          style={{
            height: 450,
            backgroundColor: theme.backgroundColor,
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
          }}
        >

          <View
            style={{width: 250, flex: 1, alignItems: "center", justifyContent: 'center'}}
          >
            {/*<Text
              style={[
                globalStyles.fontXXL,
                globalStyles.font_FamilyMedium,
                {
                  //color: "red",
                  margin: 20,
                },
              ]}
            >
              {_.startCase(type)}
            </Text>*/}
            {type === 'signIn' ? <>
                <TouchableOpacity
                  onPress={() => socialLogin('google')}
                >
                  <Image
                    source={require('../../assets/google_login_long.png')}
                    style={{
                      width: 250,
                      height: 60,
                    }}
                  />
                </TouchableOpacity>
                <Text
                  style={[
                    globalStyles.fontMD,
                    globalStyles.font_FamilyRegular,
                    {
                      margin: 20,
                    },
                  ]}
                >
                  Or Login Using Email
                </Text>
              </>
              :
              <TextInput
                placeholder={"Name"}
                //placeholderTextColor={"#c5c5c5"}
                style={[
                  styles.input,
                  {
                    height: 50,
                  },
                ]}
                value={name}
                onChangeText={setName}
              />
            }

            <TextInput
              placeholder={"Email"}
              //placeholderTextColor={"#c5c5c5"}
              style={[
                styles.input,
                {
                  height: 50,
                  marginTop: 10,
                },
              ]}
              value={email}
              onChangeText={setEmail}
            />
            <TextInput
              placeholder={"Password"}
              //placeholderTextColor={"#c5c5c5"}
              secureTextEntry
              style={[
                styles.input,
                {
                  height: 50,
                  marginTop: 10,
                },
              ]}
              value={password}
              onChangeText={setPassword}
            />
            <Button
              style={{
                marginTop: 10,
                width: 250,
                height: 50
              }}
              onPress={() => {
                if (type === 'signIn') {
                  signIn()
                } else {
                  signUp()
                }
              }}
            >
              {_.startCase(type)}
            </Button>
            <TouchableOpacity
              onPress={() => setType(type === 'signUp' ? 'signIn' : 'signUp')}
            >
              <Text
                style={[
                  globalStyles.fontSM,
                  globalStyles.font_FamilyRegular,
                  {
                    marginTop: 10,
                    textAlign: 'center',
                    color: type === 'signIn' ? '#0485ff' : 'red'
                  },
                ]}
              >
                {type === 'signIn' ? `Don't have an account? Click here to Sign Up.` : 'Already have an account? Click here to Sign In.'}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              //height: 100,
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                //backgroundColor: 'red'
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  //navigation.push("chat")
                }}
              >
                <Image
                  source={require("../../assets/powered-by-arx.png")}
                  style={{
                    width: Platform.OS === "web" ? 150 : componentHeight * 0.38,
                    height: Platform.OS === "web" ? 40 : componentHeight * 0.1,
                  }}
                  resizeMode={"contain"}
                />
              </TouchableOpacity>

            </View>
          </View>
        </View>
      }
    </ImageBackground>
  );
}

const styles = {
  input: {
    backgroundColor: "white",
    padding: 15,
    borderRadius: 10,
    width: "100%",
  },
};

export default redux(
  ["details", 'user'],
  ["mergeDetails", "setLoading", "setDetails"]
)(HomeScreen);
