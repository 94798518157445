import React, { useEffect, useState } from "react";
import {
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
  Dimensions,
} from "react-native";
import { api, redux } from "../helper";
import { Slider, Switch } from "@rneui/themed";
import _ from "lodash";
import globalStyles from "../../styles/global.css";
import Button from "../Components/Button";
import { useTimer } from "../Components/TimerProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";

const windowDimensions = Dimensions.get("window");
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function Vitals(props) {
  const { route, navigation, mergeDetails, Translate, details } = props;
  const { elapsedSeconds, resetTimer } = useTimer();
  return (
    <View
      style={{
        flex: 1,
        padding: 20,
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1 }}>
        <Text
          style={[
            globalStyles.fontXL,
            globalStyles.font_FamilyMedium,
            {
              textAlign: "center",
            },
          ]}
        >
          It would be nice if you could provide the information below.
        </Text>
        <View
          style={{
            flexDirection: "row",
            marginTop: 30,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={[
                  globalStyles.fontSM,
                  globalStyles.font_FamilyMedium,
                  {},
                ]}
              >
                Blood Pressure (mmHg)
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: "column" }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <TextInput
                  placeholder={"100"}
                  placeholderTextColor={"#c5c5c5"}
                  style={[
                    styles.input,
                    {
                      marginRight: 10,
                      height: Platform.OS === "web" ? 60 : componentHeight * 0.07,
                      flex: 1
                    },
                  ]}
                  value={_.get(details, "bloodPressure.systolic", "")}
                  onChangeText={(text) => {
                    if (/^\d*((\d\.)|(\.\d))?\d{0,3}$/.test(text)) {
                      mergeDetails("bloodPressure.systolic", text);
                    }
                  }}
                />
                <Text style={[{}]}>/</Text>
                <TextInput
                  placeholder={"60"}
                  placeholderTextColor={"#c5c5c5"}
                  style={[
                    styles.input,
                    {
                      marginLeft: 10,
                      height: Platform.OS === "web" ? 60 : componentHeight * 0.07,
                      flex: 1
                    },
                  ]}
                  value={_.get(details, "bloodPressure.diastolic", "")}
                  onChangeText={(text) => {
                    if (/^\d*((\d\.)|(\.\d))?\d{0,3}$/.test(text)) {
                      mergeDetails("bloodPressure.diastolic", text);
                    }
                  }}
                />
              </View>

            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            marginTop: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={[
                  globalStyles.fontSM,
                  globalStyles.font_FamilyMedium,
                  {},
                ]}
              >
                Pulse Rate (BPM)
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <TextInput
                placeholder={"90"}
                placeholderTextColor={"#c5c5c5"}
                style={[
                  styles.input,
                  {
                    height: Platform.OS === "web" ? 60 : componentHeight * 0.07,
                    flex: 1
                  },
                ]}
                value={_.get(details, "pulseRate", "")}
                onChangeText={(text) => {
                  if (/^\d*((\d\.)|(\.\d))?\d{0,3}$/.test(text)) {
                    mergeDetails("pulseRate", text);
                  }
                }}
              />
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            marginTop: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={[
                  globalStyles.fontSM,
                  globalStyles.font_FamilyMedium,
                  {},
                ]}
              >
                Temperature (°C)
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <TextInput
                placeholder={"37.1"}
                placeholderTextColor={"#c5c5c5"}
                style={[
                  styles.input,
                  {
                    height: Platform.OS === "web" ? 60 : componentHeight * 0.07,
                    flex: 1
                  },
                ]}
                value={_.get(details, "temperature", "")}
                onChangeText={(text) => {
                  if (/^\d*((\d\.)|(\.\d))?\d{0,3}$/.test(text)) {
                    mergeDetails("temperature", text);
                  }
                }}
              />
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            marginTop: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={[
                  globalStyles.fontSM,
                  globalStyles.font_FamilyMedium,
                  {},
                ]}
              >
                Pulse Oximetry (%)
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <TextInput
                placeholder={"98"}
                placeholderTextColor={"#c5c5c5"}
                style={[
                  styles.input,
                  {
                    height: Platform.OS === "web" ? 60 : componentHeight * 0.07,
                    flex: 1
                  },
                ]}
                value={_.get(details, "pulseOximetry", "")}
                onChangeText={(text) => {
                  if (/^\d*((\d\.)|(\.\d))?\d{0,3}$/.test(text)) {
                    mergeDetails("pulseOximetry", text);
                  }
                }}
              />
            </View>
          </View>
        </View>
      </View>
      <Button
        onPress={async () => {
          const activities = JSON.parse(await AsyncStorage.getItem('userActivity'));
          api('user-activities', 'POST', {
            _id: activities._id,
            checker: true,
            timeTaken: elapsedSeconds,
            paymentMethod: 'Corporate Billing',
          })
            .then(activitiesRes => {
              console.log('User Activities:', activitiesRes);
            })
            .catch(error => {
              console.error('Error fetching user activities:', error);
            });
            resetTimer();
          navigation.reset({
            index: 1,
            routes: [{ name: "home_container" }, { name: "conditions" }],
          });
        }}
      >
        Next
      </Button>
      <Text
        style={[
          globalStyles.fontXXS,
          globalStyles.font_FamilyLight,
          {
            paddingTop: 15,
          },
        ]}
      >
        This is optional you may press next to Skip.
      </Text>
    </View>
  );
}

const styles = {
  input: {
    backgroundColor: "white",
    padding: 15,
    borderRadius: 10,
    width: "100%",
  },
};

export default redux(["details"], ["mergeDetails"])(Vitals);
