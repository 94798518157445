import React, { useEffect, useState } from 'react';
import {
	Platform,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import { api, baseUrl, redux } from '../helper';
import { Slider, Switch } from '@rneui/themed';
import _ from 'lodash';
// import Markdown from "react-native-markdown-renderer";
import globalStyles from '../../styles/global.css';
import Markdown from 'react-native-markdown-display';
import Button from '../Components/Button';
import { Image } from 'react-native';

function TeleconsulltationDetailsScreen(props) {
	const { route, navigation, setDetails, Translate } = props;
	const { resultId } = route.params;
	useEffect(() => {
		const unsubscribe = navigation.addListener('beforeRemove', e => {
			e.preventDefault();

			unsubscribe();
			navigation.replace('teleconsultation', {
				resultId,
			});
		});

		return unsubscribe;
	}, [navigation]);
	return (
		<View style={{ flex: 1, padding: 20 }}>
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 20,
				}}
			>
				Here is teleconsultation details page
			</View>
		</View>
	);
}

export default redux([], ['setDetails'])(TeleconsulltationDetailsScreen);
