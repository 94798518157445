import React, { useEffect, useRef, useState } from 'react';
import {
	Text,
	View,
	Image,
	Platform,
	ScrollView,
	ImageBackground,
	TouchableOpacity,
	TouchableWithoutFeedback,
	Dimensions,
	Linking,
} from 'react-native';
import { redux, Translation, theme, api } from '../helper';
import _ from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign, Entypo } from '@expo/vector-icons';
import SelectionModal from '../Components/SelectionModal';
import Button from '../Components/Button';
import TNC from '../Components/TNC';
import WebView from 'react-native-webview';
import globalStyles from '../../styles/global.css';
import Header from '../Components/Header';
import { useTimer } from '../Components/TimerProvider';

const windowDimensions = Dimensions.get('window');
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function HomeScreen(props) {
	const {
		details,
		navigation,
		setLanguage,
		language,
		languages,
		Translate,
		setLoading,
		setDetails,

	} = props;
	const [view, setView] = useState('t&c');
	const [disabled, setDisabled] = useState(false);
	const { startTimer } = useTimer();
	useEffect(() => {
		navigation.setOptions({
			headerShown: false,
		});
	}, []);

	useEffect(() => {
		if (Platform.OS === 'web') {
			setDisabled(true);
		}
	}, []);

	return (
		<ImageBackground
			style={{
				flex: 1,
				paddingTop: 30,
			}}
			source={require('../../assets/bg.png')}
			resizeMode={'cover'}
		>
			<Header {...props} />
			<View
				style={{ flex: 0.5, alignItems: 'center', justifyContent: 'center' }}
			>
				<Image
					source={require('../../assets/logo.png')}
					style={{
						width: Platform.OS === 'web' ? 260 : componentHeight * 0.65,
						height: Platform.OS === 'web' ? 70 : componentHeight * 0.18,
						resizeMode: 'contain',
					}}
				/>
				<Text
					style={[
						globalStyles.fontXS,
						globalStyles.font_FamilyRegular,
						{
							color: 'white',
						},
					]}
				>
					{Translate.getStatic('slogan')}
				</Text>
			</View>
			<View
				style={{
					flex: 1.5,
					alignItems: 'center',
					marginTop: 20,
					flexDirection: 'column',
				}}
			>
				<View
					style={{
						backgroundColor: 'white',
						justifyContent: 'center',
						flexDirection: 'row',
						width: '90%',
						borderRadius: 10,
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
						overflow: 'hidden',
					}}
				>
					<TouchableWithoutFeedback onPress={() => setView('t&c')}>
						<View
							style={{
								flex: 1,
								height: Platform.OS === 'web' ? 40 : componentHeight * 0.1,
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: view === 't&c' ? theme.primary : 'white',
							}}
						>
							<Text
								style={[
									globalStyles.fontSM,
									globalStyles.font_FamilyBold,
									{
										color: theme.secondary,
									},
								]}
							>
								{Translate.getStatic('t&c')}
							</Text>
						</View>
					</TouchableWithoutFeedback>
					<TouchableWithoutFeedback onPress={() => setView('announcement')}>
						<View
							style={{
								flex: 1,
								height: Platform.OS === 'web' ? 40 : componentHeight * 0.1,
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor:
									view === 'announcement' ? theme.primary : 'white',
							}}
						>
							<Text
								style={[
									globalStyles.fontSM,
									globalStyles.font_FamilyBold,
									{
										color: theme.secondary,
									},
								]}
							>
								{Translate.getStatic('announcement')}
							</Text>
						</View>
					</TouchableWithoutFeedback>
				</View>
				<View
					style={{
						marginBottom: 30,
						backgroundColor: 'white',
						width: '90%',
						borderRadius: 10,
						flex: 1,
						paddingHorizontal: 10,
						borderWidth: 5,
						borderColor: theme.primary,
						borderTopRightRadius: 0,
						borderTopLeftRadius: 0,
					}}
				>
					{view === 't&c' && <TNC />}
					{view === 'announcement' && <Announcement />}
				</View>
			</View>
			<View
				style={{
					flex: 1,
					backgroundColor: theme.backgroundColor,
					alignItems: 'center',
					justifyContent: 'center',
					borderTopRightRadius: 25,
					borderTopLeftRadius: 25,
				}}
			>
				<View
					style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
				>
					<Button
						onPress={() => {
							startTimer()
							// AsyncStorage.setItem('startTime', new Date());
							navigation.push('basic');
						}}
					>
						{Translate.getStatic('get_started')}
					</Button>
					<Text
						style={[
							globalStyles.fontSM,
							{
								textAlign: 'center',
								margin: 10,
								marginRight: 20,
								marginLeft: 20,
							},
						]}
					>
						{Translate.getStatic('by_clicking')}
						{Translate.getStatic('terms&conditions')}
					</Text>
					{/*<TouchableOpacity
            onPress={() => {
              Linking.openURL("https://mediverse.my/")
            }}
          >
            <Text
              style={[
                globalStyles.fontSM,
                {
                  textAlign: "center",
                  margin: 10,
                  marginRight: 20,
                  marginLeft: 20
                },
              ]}
            >
              Thank you for visiting our Symptom Checker and exhibition at ITEX 2023 to make it a great success! Our symptom checker is now upgrading and will be back to serve you very soon, stay tuned. Visit <Text style={{color: '#00baff', fontWeight: 'bold'}}>www.mediverse.my</Text> to know more!
            </Text>
          </TouchableOpacity>*/}
				</View>

				<View
					style={{
						//height: 100,
						alignItems: 'center',
						justifyContent: 'center',
						marginBottom: 5,
					}}
				>
					{/* <TouchableOpacity onPress={() => navigation.push("survey")}>
            <Text
              style={[
                globalStyles.fontMD,
                globalStyles.font_FamilyBold,
                {
                  color: "red",
                  marginBottom: 10,
                },
              ]}>
              {Translate.getStatic("research_survey")}
            </Text>
          </TouchableOpacity> */}
					{Platform.OS !== 'web' && (
						<TouchableOpacity
							onPress={() => navigation.push('qr_code')}
							disabled={disabled}
						>
							<Text
								style={[
									globalStyles.fontMD,
									globalStyles.font_FamilyBold,
									{
										color: 'red',
										marginBottom: 10,
									},
								]}
							>
								Scan your QR for previous report
							</Text>
						</TouchableOpacity>
					)}
					{/*<View
            style={{
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              //backgroundColor: 'red'
            }}
          >
            <TouchableOpacity
              onPress={() => {
                //navigation.push("chat")
              }}
            >
              <Image
                source={require("../../assets/powered-by-arx.png")}
                style={{
                  width: Platform.OS === "web" ? 150 : componentHeight * 0.38,
                  height: Platform.OS === "web" ? 40 : componentHeight * 0.1,
                }}
                resizeMode={"contain"}
              />
            </TouchableOpacity>
            <Entypo
              style={{
                marginLeft: 10
              }}
              name="chat"
              size={24}
              color="black"
            />
          </View>*/}
				</View>
			</View>
		</ImageBackground>
	);
}

const Announcement = () => {
	return (
		<View style={{ flex: 1 }}>
			{Platform.OS === 'web' ? (
				<iframe
					src={'https://ai.mediverse.my/annoucement.html'}
					style={{
						border: 0,
						height: '100%',
					}}
				/>
			) : (
				<WebView
					source={{
						uri: 'https://ai.mediverse.my/annoucement.html',
					}}
					style={{
						flex: 1,
						height: '100%',
						//backgroundColor: 'red'
					}}
				/>
			)}
		</View>
	);
};

export default redux(
	['details', 'language'],
	['mergeDetails', 'setLanguage', 'setLoading', 'setDetails']
)(HomeScreen);
