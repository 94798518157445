import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyABUDIBtY9U1l62v66vhM9zUWq7T08gUl4",
  authDomain: "mediverse-350718.firebaseapp.com",
  projectId: "mediverse-350718",
  storageBucket: "mediverse-350718.appspot.com",
  messagingSenderId: "629899726869",
  appId: "1:629899726869:web:0de64acee6be5a6dcfbe1c"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
  app,
  auth
}