import * as React from "react";
import _ from "lodash";
import { api, theme, redux } from "../helper";
import { useEffect, useState } from "react";
import {
  ImageBackground,
  View,
  Text,
  Touchable,
  Button,
  Platform,
} from "react-native";
import Svg, { Path, G } from "react-native-svg";

const bodyImages = {
  "male-front": require("../../assets/male-body.png"),
  "male-back": require("../../assets/male-body-back.png"),
  "female-front": require("../../assets/female-body.png"),
  "female-back": require("../../assets/female-body-back.png"),
};

function Body({
  area,
  setArea,
  details,
  side,
  componentHeight,
  componentWidth,
}) {
  const gender = details?.gender || "male";

  const [loading, setLoading] = useState(true);
  const [svgData, setSvgData] = useState({});

  useEffect(() => {
    setLoading(true);
    api(`svg/${gender}/${side}`)
      .then((res) => {
        setSvgData(res.result);
      })
      .finally(() => setLoading(false));
  }, [gender, side]);

  const parts = [
    {
      id: "general",
    },
    {
      id: "head",
    },
    {
      id: "neck",
    },
    {
      id: "chest",
    },
    {
      id: "arm",
    },
    {
      id: "wristAndHand",
    },
    {
      id: "upperAbdomen",
    },
    {
      id: "middleAbdomen",
    },
    {
      id: "lowerAbdomen",
    },
    {
      id:
        gender === "male" ? "maleReproductiveOrgan" : "femaleReproductiveOrgan",
    },
    {
      id: "hip",
    },
    {
      id: "lowerLimb",
    },
    {
      id: "knee",
    },
    {
      id: "footAndAnkle",
    },
    {
      id: "back",
    },
    {
      id: "buttock",
    },
    {
      id: "anus",
    },
  ];

  if (loading) {
    return (
      <View
        style={{
          width: Platform.OS === "web" ? 300 : componentWidth * 0.9,
          height: Platform.OS === "web" ? 595 : componentWidth * 1.8,
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <ImageBackground
      source={bodyImages[`${gender}-${side}`]}
      style={{
        width: Platform.OS === "web" ? 300 : componentWidth * 0.9,
        height: Platform.OS === "web" ? 595 : componentWidth * 1.8,
        marginVertical: 15,
        //backgroundColor: 'black'
      }}
      //resizeMode={'contain'}
    >
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={Platform.OS === "web" ? 300 : componentWidth * 0.9}
        height={Platform.OS === "web" ? 600 : componentWidth * 1.8}
        viewBox={_.get(svgData, "viewBox")}>
        <G fill="rgba(0,0,0,0)">
          {_.map(parts, ({ id }) => {
            return (
              <G
                key={id}
                onPress={() => setArea(id)}
                onClick={() => setArea(id)}>
                {_.map(_.get(svgData, `data.${id}`), (props, index) => {
                  if (id === area) {
                    props.fill = theme.primary;
                    props.opacity = "50%";
                    props.stroke = theme.primary;
                  } else {
                    props.stroke = "#fff";
                    props.fill = null;
                    props.opacity = "100%";
                  }
                  return (
                    <Path strokeWidth={5} key={`${id}-${index}`} {...props} />
                  );
                })}
              </G>
            );
          })}
        </G>
      </Svg>
    </ImageBackground>
  );
}

export default redux(["details"])(Body);
