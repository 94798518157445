import React, { useCallback, useEffect, useRef, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { api } from "../helper";
import _ from "lodash";
import {
  Bubble,
  Composer,
  GiftedChat,
  InputToolbar,
} from "react-native-gifted-chat";
import { TypingAnimation } from "react-native-typing-animation";
import { Audio } from "expo-av";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

const { v4: uuidv4 } = require("uuid");

function ChatScreen(props) {
  const { details, navigation, mergeDetails, Translate, route } = props;

  const chatRef = useRef();

  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [action, setAction] = useState("general");
  const [recording, setRecording] = React.useState();

  useEffect(() => {
    navigation.setOptions({
      headerStyle: {
        backgroundColor: "#293267",
      },
      headerTitleStyle: {
        color: "white",
      },
    });

    sendDoctorReply(
      "Hi, I am STARBOX\r\n\r\nWhat you would like to ask today?",
      true
      /*{
        type: 'radio',
        keepIt: false,
        values: [
          {
            title: 'General Question',
            value: 'question',
          }
        ],
      }*/
    );
  }, []);

  const ask = (message) => {
    setTyping(true);
    api(`chats/ask2/${action}`, "post", {
      message,
    })
      .then(async ({ text, voiceBase64 }) => {
        sendDoctorReply(text, false);
        if (voiceBase64) {
          try {
            const voiceBlob = await fetch(voiceBase64).then((res) =>
              res.blob()
            );
            const voiceBlobUrl = window.URL.createObjectURL(voiceBlob);
            const sound = new Audio.Sound();
            await sound.loadAsync(voiceBlobUrl);
            await sound.playAsync();
            //await sound.unloadAsync();
          } catch (e) {
            console.error(e);
          }
        }
      })
      .finally(() => {
        setTyping(false);
      });
  };

  const sendDoctorReply = (text, delay = true, quickReplies) => {
    setTyping(true);
    setTimeout(
      () => {
        addMessage([
          {
            _id: uuidv4(),
            text,
            createdAt: new Date(),
            user: {
              _id: 2,
              name: "STARBOX",
              avatar:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQayy6T_GVmExyhLh5YhEMTpNHrk70JIJHTAw&usqp=CAU",
            },
            quickReplies,
          },
        ]);
        setTyping(false);

        setTimeout(() => {
          console.log(chatRef.current.focus());
        }, 100);
      },
      delay ? _.random(100, 1000) : 0
    );
  };

  useEffect(() => {
    //console.log(_.first(messages))
    //console.log(chatRef.current)
  }, [messages]);

  const onQuickReply = (replies) => {
    const { value, title } = _.get(replies, "0", {});
    setAction(value);
    addMessage([
      {
        _id: uuidv4(),
        text: title,
        createdAt: new Date(),
        user: {
          _id: 1,
        },
      },
    ]);

    if (value === "question") {
      sendDoctorReply("What would you like to know?");
    }
  };

  const recordingSettings = React.useMemo(
    () => ({
      android: {
        extension: ".m4a",
        outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_MPEG_4,
        audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_HE_AAC,
        sampleRate: 44100,
        numberOfChannels: 2,
        bitRate: 64000,
      },
      ios: {
        extension: ".m4a",
        outputFormat: Audio.RECORDING_OPTION_IOS_OUTPUT_FORMAT_MPEG4AAC_HE,
        audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_MIN,
        sampleRate: 44100,
        numberOfChannels: 2,
        bitRate: 64000,
        linearPCMBitDepth: 16,
        linearPCMIsBigEndian: false,
        linearPCMIsFloat: false,
      },
      web: (() => {
        const mimes = [
          {
            mimeType: 'audio/mp4;codecs="mp4a.40.5"', // MPEG-4 HE-AAC v1
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: ".m4a",
          },
          {
            mimeType: 'audio/mp4;codecs="mp4a.40.2"', // MPEG-4 AAC LC
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: ".m4a",
          },
          {
            mimeType: 'audio/webm;codecs="opus"',
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: ".webm",
          },
          {
            mimeType: 'audio/webm;codecs="vp8"',
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: ".webm",
          },
          {
            mimeType: "audio/webm",
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: ".webm",
          },
          {
            mimeType: "audio/mpeg", // Support depends on polyfill
            audioBitsPerSecond: 128000,
            bitsPerSecond: 128000,
            extension: ".mp3",
          },
        ];

        for (let index = 0; index < mimes.length; index++) {
          const mime = mimes[index];

          if (window.MediaRecorder.isTypeSupported(mime.mimeType)) {
            console.log(mime);
            return mime;
          }
        }

        return false;
      })(),
    }),
    [window.MediaRecorder]
  );

  async function startRecording() {
    try {
      console.log("Requesting permissions..");
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      //console.log('Starting recording..', Audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY.web);
      const { recording } = await Audio.Recording.createAsync(
        recordingSettings
      );
      setRecording(recording);
      console.log("Recording started");
    } catch (err) {
      console.error("Failed to start recording", err);
    }
  }

  async function stopRecording() {
    console.log("Stopping recording..");
    setRecording(undefined);
    await recording.stopAndUnloadAsync();
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
    });
    const uri = recording.getURI();

    console.log("recording", recording);
    console.log("Recording stopped and stored at", uri);

    const base64 = await blobToBase64(uri);

    api("chats/voice/transcribe", "post", { base64 }).then((data) => {
      let { transcription } = data;
      transcription = transcription.replace("Starbucks", "STARBOX");
      if (transcription !== "") {
        addMessage([
          {
            _id: uuidv4(),
            text: transcription,
            createdAt: new Date(),
            user: {
              _id: 1,
            },
          },
        ]);
        ask(transcription);
      }
    });

    /*    try {
          const sound = new Audio.Sound();
          await sound.loadAsync(uri);
          await sound.playAsync();
          //await sound.unloadAsync();
        } catch (e) {
          console.error(e)
        }*/
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onloadend = function () {
        resolve(fileReader.result); // Here is the base64 string
      };
    });
  };

  const addMessage = useCallback((messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
  }, []);

  const onSend = useCallback(
    (messages = []) => {
      ask(messages[0].text);
      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, messages)
      );
    },
    [action]
  );

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#28bdfe",
      }}>
      <GiftedChat
        messages={messages}
        onSend={(messages) => onSend(messages)}
        onQuickReply={onQuickReply}
        quickReplyStyle={{
          backgroundColor: "#293267",
          paddingHorizontal: 10,
          minHeight: 40,
          borderStyle: "none",
        }}
        quickReplyTextStyle={{
          color: "white",
          fontFamily: "Poppins_500Medium",
          fontSize: 14,
        }}
        renderBubble={(props) => {
          return (
            <Bubble
              {...props}
              textStyle={{
                left: {
                  fontFamily: "Poppins_400Regular",
                },
                right: {
                  fontFamily: "Poppins_400Regular",
                },
              }}
              wrapperStyle={{
                left: {
                  padding: 10,
                },
                right: {
                  padding: 10,
                },
              }}
            />
          );
        }}
        textInputStyle={
          {
            //display: 'none'
          }
        }
        placeholder={action ? undefined : "Please select a action..."}
        renderFooter={() => {
          if (!typing) {
            return null;
          }
          return (
            <View
              style={{
                height: 40,
                margin: 10,
              }}>
              <View
                style={{
                  height: 40,
                  width: 200,
                  backgroundColor: "white",
                  borderRadius: 20,
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <View style={{ marginLeft: 10, marginTop: -20 }}>
                  <TypingAnimation />
                </View>
                <Text style={{ marginLeft: 35 }}>STARBOX is typing...</Text>
              </View>
            </View>
          );
        }}
        renderInputToolbar={(props) => {
          return (
            <InputToolbar
              {...props}
              textInputProps={{
                ref: chatRef,
                disabled: !action || typing,
                onKeyPress: (e) => {
                  if (e.nativeEvent.key === "Enter") {
                    if (props.text && props.onSend) {
                      let text = props.text;
                      props.onSend({ text: text.trim() }, true);
                    }
                  }
                },
              }}
            />
          );
        }}
        renderComposer={(props) => {
          return (
            <View style={{ flexDirection: "row", flex: 1 }}>
              <TouchableOpacity
                //onPressIn={() => startRecording()}
                //onPressOut={() => stopRecording()}
                onPress={() => {
                  if (!recording) {
                    startRecording();
                  } else {
                    stopRecording();
                  }
                }}
                style={{
                  paddingLeft: 15,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <MaterialIcons
                  name="keyboard-voice"
                  size={24}
                  color={recording ? "red" : "black"}
                />
              </TouchableOpacity>
              <Composer {...props} />
            </View>
          );
        }}
        user={{
          _id: 1,
        }}
      />
    </View>
  );
}

export default ChatScreen;
