import React, { useEffect, useState } from 'react';
import {
	Image,
	ScrollView,
	Text,
	TextInput,
	TouchableOpacity,
	View,
	Dimensions,
	Platform,
} from 'react-native';
import { redux, theme } from '../helper';
import { Slider, Switch } from '@rneui/themed';
import _ from 'lodash';
import StepsBar from '../Components/StepsBar';
import Button from '../Components/Button';
import validator from 'validator';
import { Foundation } from '@expo/vector-icons';
import globalStyles from '../../styles/global.css';
import { useTimer } from '../Components/TimerProvider';

const windowDimensions = Dimensions.get('window');
const componentHeight = windowDimensions.height * 0.5;
const componentWidth = windowDimensions.width * 0.5;

function BasicScreen(props) {
	const { details, navigation, mergeDetails, Translate } = props;
	const { resetTimer } = useTimer();
	useEffect(() => {
		const unsubscribe = navigation.addListener('beforeRemove', e => {
			e.preventDefault();

			resetTimer();
			unsubscribe();

			navigation.dispatch(e.data.action);
		});

		return unsubscribe;
	}, [navigation, resetTimer]);
	return (
		<ScrollView style={{ flex: 1, padding: 20 }}>
			<StepsBar step={1} />

			<View style={{ alignItems: 'center', flex: 1 }}>
				<View style={{ marginTop: 20, alignItems: 'center' }}>
					<Text
						style={[
							globalStyles.fontXL,
							globalStyles.font_FamilyBold,
							{
								color: theme.secondary,
							},
						]}
					>
						{Translate.getStatic('what_gender')}
					</Text>
					<View
						style={{
							flexDirection: 'row',
							marginTop: 20,
						}}
					>
						<View style={{ alignItems: 'center' }}>
							<TouchableOpacity
								onPress={() => mergeDetails('gender', 'male')}
								style={{
									height: Platform.OS === 'web' ? 120 : componentHeight * 0.3,
									width: Platform.OS === 'web' ? 120 : componentHeight * 0.3,
									backgroundColor:
										_.get(details, 'gender') === 'male' ? '#5bbcf2' : 'white',
									borderRadius: 15,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Foundation
									name='male-symbol'
									size={Platform.OS === 'web' ? 100 : componentWidth * 0.4}
									color={
										_.get(details, 'gender') !== 'male' ? '#5bbcf2' : 'white'
									}
								/>
							</TouchableOpacity>
							<Text
								style={[
									globalStyles.fontSM,
									globalStyles.font_FamilyBold,
									{
										color: theme.secondary,
										marginTop: 10,
									},
								]}
							>
								{Translate.getStatic('male')}
							</Text>
						</View>
						<View style={{ alignItems: 'center' }}>
							<TouchableOpacity
								onPress={() => mergeDetails('gender', 'female')}
								style={{
									height: Platform.OS === 'web' ? 120 : componentHeight * 0.3,
									width: Platform.OS === 'web' ? 120 : componentHeight * 0.3,
									backgroundColor:
										_.get(details, 'gender') === 'female' ? '#fe477e' : 'white',
									borderRadius: 15,
									alignItems: 'center',
									justifyContent: 'center',
									marginLeft: 20,
								}}
							>
								<Foundation
									name='female-symbol'
									size={Platform.OS === 'web' ? 100 : componentWidth * 0.4}
									color={
										_.get(details, 'gender') !== 'female' ? '#fe477e' : 'white'
									}
								/>
							</TouchableOpacity>
							<Text
								style={[
									globalStyles.fontSM,
									globalStyles.font_FamilyBold,
									{
										color: theme.secondary,
										marginTop: 10,
									},
								]}
							>
								{Translate.getStatic('female')}
							</Text>
						</View>
					</View>
				</View>

				<View style={{ marginVertical: 20, alignItems: 'center' }}>
					<Text
						style={[
							globalStyles.fontXL,
							globalStyles.font_FamilyBold,
							{
								color: theme.secondary,
							},
						]}
					>
						{Translate.getStatic('how_old')}
					</Text>
					<TextInput
						style={[
							globalStyles.fontDoubleXL,
							globalStyles.font_FamilyBold,
							{
								color: theme.secondary,
								textAlign: 'center',
								height: Platform.OS === 'web' ? 60 : componentHeight * 0.15,
								width: Platform.OS === 'web' ? 80 : componentHeight * 0.2,
							},
						]}
						keyboardType='numeric'
						value={_.get(details, 'age', '0').toString()}
						onChange={e => {
							const value = e.target.value;
							if (validator.isEmpty(value)) {
								mergeDetails('age', '');
							} else if (validator.isNumeric(value)) {
								mergeDetails(
									'age',
									value.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, '')
								);
							}
						}}
					/>
					<Text
						style={[
							globalStyles.fontXS,
							globalStyles.font_FamilyBold,
							{
								color: theme.secondary,
								marginTop: 0,
							},
						]}
					>
						{Translate.getStatic('years_old')}
					</Text>
					<Slider
						value={_.get(details, 'age', 0)}
						onValueChange={age => mergeDetails('age', age)}
						maximumValue={99}
						minimumValue={0}
						step={1}
						allowTouchTrack
						minimumTrackTintColor={theme.primary}
						trackStyle={{ height: 5 }}
						thumbStyle={{
							height: Platform.OS === 'web' ? 30 : componentHeight * 0.045,
							width: Platform.OS === 'web' ? 30 : componentHeight * 0.045,
							backgroundColor: theme.primary,
						}}
						style={{
							width: Platform.OS === 'web' ? 300 : componentHeight * 0.75,
							marginTop: 10,
						}}
					/>
				</View>

				<View
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						paddingBottom: 20,
					}}
				>
					<Text
						style={[
							globalStyles.fontSM,
							globalStyles.font_FamilyRegular,
							{
								color: theme.secondary,
								textAlign: 'center',
								marginBottom: 20,
							},
						]}
					>
						{Translate.getStatic('customize_info')}
					</Text>
					<Button
						onPress={() => {
							if (!details?.age || !details?.gender) {
								return alert('Age and gender is required.');
							}
							navigation.push('known_illnesses');
						}}
						disabled={!details?.age || !details?.gender}
					>
						{Translate.getStatic('continue')}
					</Button>
				</View>
			</View>
		</ScrollView>
	);
}

export default redux(['details'], ['mergeDetails'])(BasicScreen);
