import React, { useEffect, useState } from 'react';
import {
	Platform,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import { api, baseUrl, redux } from '../helper';
import { Slider, Switch } from '@rneui/themed';
import _ from 'lodash';
// import Markdown from "react-native-markdown-renderer";
import globalStyles from '../../styles/global.css';
import Markdown from 'react-native-markdown-display';
import Button from '../Components/Button';
import { Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTimer } from '../Components/TimerProvider';

function TeleconsultationScreen(props) {
	const { route, navigation, setDetails, Translate } = props;

	const { resultId } = route.params;

	const handleUpdateUserActivity = async teleconsultation => {
		// const startTime = new Date(await AsyncStorage.getItem('startTime'));
		const activities = JSON.parse(await AsyncStorage.getItem('userActivity'));
		// console.log(startTime);
		// const endTime = new Date();
		// const duration = (endTime - elapsedSeconds) / 1000;
		// console.log(duration);

		api('user-activities', 'POST', {
			_id: activities._id,
			checker: true,
			teleconsultation: teleconsultation,
			provider: teleconsultation ? 'abc company' : '',
			clinic: teleconsultation ? 'abc Clinic' : '',
			paymentMethod: 'Corporate Billing',
		})
			.then(activitiesRes => {
				console.log('User Activities:', activitiesRes);
			})
			.catch(error => {
				console.error('Error fetching user activities:', error);
			});
		await AsyncStorage.removeItem('userActivity');
		// await AsyncStorage.removeItem('startTime');
	};
	return (
		<View style={{ flex: 1, padding: 20 }}>
			<View style={{ alignItems: 'center' }}>
				<Image
					style={{
						height: 150,
						width: 150,
					}}
					source={require('../../assets/hippo.png')}
				/>
			</View>
			{/* <View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 20,
				}}
			></View> */}
			{Platform.OS === 'web' && (
				<Button
					style={{ width: '100%' }}
					onPress={() => {
						window.open(`${baseUrl}/api/conditions/pdf/${resultId}`, '_blank');
					}}
				>
					{Translate.getStatic('download')}
				</Button>
			)}
			<Button
				style={{ marginTop: 15, width: '100%' }}
				onPress={() => {
					// navigation.pop();
					handleUpdateUserActivity(true);
					navigation.replace('teleconsultation_details', {
						resultId,
					});
				}}
			>
				{/* <Text
					style={{
						flex: 1,
						flexWrap: 'wrap',
						flexShrink: 1,
						alignItems: 'center',
					}}
				> */}
					{Translate.getStatic('talk')}
				{/* </Text> */}
				{/* {Translate.getStatic('talk')} */}
			</Button>
			<Button
				style={{ marginTop: 15, width: '100%' }}
				onPress={() => {
					setDetails({});
					handleUpdateUserActivity(false);
					navigation.popToTop();
					navigation.replace('home');
				}}
			>
				{Translate.getStatic('end_this')}
			</Button>
		</View>
	);
}

export default redux([], ['setDetails'])(TeleconsultationScreen);
