import React, { useEffect, useState } from "react";
import {Dimensions, Image, NativeModules, ScrollView, Text, TouchableOpacity, View, Platform} from "react-native";
import { api, redux, theme, baseUrl } from "../helper";
import { Slider, Switch } from "@rneui/themed";
import Button from "../Components/Button";
import _ from "lodash";
import { Entypo } from "@expo/vector-icons";
import globalStyles from "../../styles/global.css";
const { PrintModule } = NativeModules;
import { AntDesign } from '@expo/vector-icons';

const width = Dimensions.get("window").width;

function ConditionsScreen(props) {

  const [printUrl, setPrintUrl] = useState(false)
  const [resultId, setResultId] = useState('')

  const {
    details,
    navigation,
    setDetails,
    conditions,
    setConditions,
    Translate,
  } = props;

  //console.log("printUrl", printUrl);

  useEffect(() => {
    const {conditions, _resultId} = _.get(props, 'route.params', {})

    if(_resultId) {
      setResultId(_resultId)
    } else {
      navigation.setOptions({
        headerTitle: "",
        headerLeft: () => {
          return (
            <TouchableOpacity
              style={{
                padding: 15,
              }}
              onPress={() => {
                if(Platform.OS === 'web' && !_.isEmpty(conditions)) {
                  window.location = window.location.origin
                } else {
                  navigation.replace("home_container");
                }
                setDetails({});
              }}>
              <Entypo name="home" size={30} color={theme.primary} />
            </TouchableOpacity>
          );
        },
      })
    }

    if(!_.isEmpty(conditions)) {
      setConditions(conditions)
      return
    }

    api("conditions/find", "post", details)
      .then((res) => {
        setConditions(res.result.data);
        console.log(res.result.data)
        //setPrintUrl(`http://192.168.0.64:19006?_resultId=${res.result._resultId}`)
        setPrintUrl(`https://ai.mediverse.my?_resultId=${res.result._resultId}`)
        setResultId(res.result._resultId)
      })
      .catch((e) => {
        alert(e.message);
      });

  }, []);

  useEffect(() => {
    return () => {
      setDetails({});
    };
  }, []);

  return (
    <ScrollView style={{ flex: 1, padding: 20 }}>
      <View style={{ alignItems: "center" }}>
        <Image
          style={{
            height: 150,
            width: 150,
          }}
          source={require("../../assets/hippo.png")}
        />
      </View>
      <View
        style={{}}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: "white",
            //minHeight: 350,
            borderRadius: 10,
            //marginTop: 20,
            padding: 20,
          }}
        >

          <Text
            style={[
              globalStyles.fontMD,
              globalStyles.font_FamilySemiBold,
              {
                flex: 1,
                textAlign: 'center'
                //marginBottom: 10
              },
            ]}>
            {Translate.getStatic("ai_generated_conditions")}
          </Text>
          {Platform.OS !== 'web' &&
            <TouchableOpacity
              onPress={() => {
                PrintModule.print(
                  printUrl,
                  "MEDIVERSE",
                  '',
                  '',
                  'Scan this to view your report.'
                );
              }}
            >
              <AntDesign name="printer" size={width * 0.03} color="black" />
            </TouchableOpacity>
          }
        </View>
        {_.map(conditions, (condition, index) => {
          Translate.load(condition);
          let evidenceText = ''
          let evidenceColor

          if(condition.evidence === 'strong') {
            evidenceText = 'Strong Evidence'
            evidenceColor = 'green'
          } else if(condition.evidence === 'moderate') {
            evidenceText = 'Moderate Evidence'
            evidenceColor = 'goldenrod'
          } else {
            evidenceText = 'Weak Evidence'
            evidenceColor = 'red'
          }
          return (
            <TouchableOpacity
              key={condition.name}
              style={{
                marginTop: 10,
                flexDirection: "column",
                backgroundColor: "white",
                //minHeight: 350,
                borderRadius: 10,
                //marginTop: 20,
                padding: 20,
              }}
              onPress={() => {
                if (!_.isEmpty(details?.symptom?.redFlagDescription)) {
                  navigation.push("red_flag", {
                    condition,
                    symptom: details?.symptom,
                    resultId
                  });
                } else {
                  navigation.push("triage", {
                    condition,
                    resultId
                  });
                }
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Text
                  style={[
                    globalStyles.fontMD,
                    globalStyles.font_FamilyMedium,
                    {
                      color: theme.primary,
                      flex: 1,
                    },
                  ]}>
                  {index + 1}. {Translate.get("name")}
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: evidenceColor,
                    textAlign: 'right',
                    ...globalStyles.font_FamilySemiBold,
                  }}
                >
                  {evidenceText}
                </Text>
              </View>

              <View
                style={{
                  backgroundColor: "#e3e3e3",
                  height: 5,
                  width: "100%",
                  borderRadius: 10,
                  overflow: "hidden",
                  flexDirection: "row",
                  marginVertical: 10
                }}>
                <View
                  style={{
                    flex: condition.score,
                    backgroundColor: theme.primary,
                  }}
                />
                <View
                  style={{
                    flex: 800 - condition.score,
                  }}
                />
              </View>
              {condition.description &&
                <Text
                  style={{
                    ...globalStyles.font_FamilyLight,
                    ...globalStyles.fontSM,
                    //textAlign: 'justify'
                  }}
                >
                  {condition.description}
                </Text>
              }
            </TouchableOpacity>
          );
        })}
      </View>
      <View style={{ margin: 10 }}>
        <Text
          style={[
            globalStyles.fontMD,
            globalStyles.font_FamilyLight,
            {
              marginBottom: 3,
            },
          ]}>
          {Translate.getStatic("disclaimer_title")}
        </Text>
        <Text style={[globalStyles.fontXXS, globalStyles.font_FamilyLight]}>
          {Translate.getStatic("disclaimer_message")}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 15,
        }}>

        <Button
          onPress={() => {
            const condition = conditions[0];
            if (!_.isEmpty(details?.symptom?.redFlagDescription)) {
              navigation.push("red_flag", {
                condition,
                symptom: details?.symptom,
                resultId
              });
            } else {
              navigation.push("triage", {
                condition,
                resultId
              });
            }
          }}>
          {Translate.getStatic("next")}
        </Button>
        {/* {Platform.OS === 'web' &&
          <Button
            style={{ marginTop: 10 }}
            onPress={() => {
              window.open(`${baseUrl}/api/conditions/pdf/${resultId}`, '_blank')
            }}
          >
            {Translate.getStatic('download')}
          </Button>
        } */}
      </View>
    </ScrollView>
  );
}

export default redux(
  ["details", "conditions"],
  ["setDetails", "setConditions"]
)(ConditionsScreen);
