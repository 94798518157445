import _ from "lodash";
import {connect} from "react-redux";
import * as Actions from "../redux/actions";
import axios from "axios";
import qs from "qs";
import {auth} from "./firebase";
import {signOut} from "firebase/auth";
import { NODE_ENV } from "@env";
const isDev = process.env.NODE_ENV === 'development';
// console.log('isDev', isDev)
let baseUrl = "https://mediverse-350718.as.r.appspot.com";

if (isDev) {
  // baseUrl = 'http://office.arxmedia.co:3002'
  baseUrl = "http://localhost:8080";
}

export const api = async (
  path,
  method = "get",
  json = {},
  setLoading,
  moreOptions = {}
) => {
  setLoading && setLoading(true);

  const headers = {
    "Content-Type": "application/json",
  };

  if(auth.currentUser) {
    headers['Authorization'] = await auth.currentUser.getIdToken()
  }

  const options = {
    method: method,
    url: `${baseUrl}/api/${path}`,
    headers,
    paramsSerializer: qs.stringify,
    onDownloadProgress: (progressEvent) => {
      const progress = _.round(
        (progressEvent.loaded / progressEvent.total) * 100,
        2
      );
      setLoading && setLoading(`Downloading ${progress.toFixed(2)}%`);
    },
    ...moreOptions,
  };

  if (method === "get") {
    options.params = json;
  } else {
    options.data = json;
  }

  return axios(options)
    .then((res) => res.data)
    .catch((e) => {
      const err = _.get(e, "response.data");
      if (err) {
        if(err.status === 401) {
          signOut(auth)
        }
        throw err;
      }
      throw e;
    })
    .finally(() => {
      setLoading && setLoading(false);
    });
};

export const getReduxProps = (list) => (states) => {
  let props = {};
  _.forEach(list, (name) => {
    _.set(props, name, states[name]);
  });
  return props;
};

export const getReduxActions = (list) => {
  let props = {};
  _.forEach(list, (name) => {
    _.set(props, name, Actions[name]);
  });
  return props;
};

export const redux =
  (props, actions, forwardRef = false) =>
    (Component) => {
      return connect(getReduxProps(props), getReduxActions(actions), null, {
        forwardRef,
      })(Component);
    };

/*export const getLang = (name, props) => {
  const {language, languages} = props;
  const translation = _.get(languages, language, {})
  return _.get(translation, name, name)
}
/*
export const getTranslation = (name, language, data) => {
  if(language === 'en') {
    return _.get(data, name, '')
  }
  return _.get(data, `translations.${language}.${name}`, '')
}*/

export class Translation {
  constructor(language, languages) {
    this.language = language;
    this.languages = languages;
  }

  load(data) {
    this.data = data;
  }

  get(name, defaultValue) {
    let fallback = _.get(this.data, name, defaultValue);
    if (this.language === "en") {
      return fallback;
    }
    return _.get(this.data, `translations.${this.language}.${name}`, fallback);
  }

  getStatic(name, defaultValue) {
    const fallback = _.get(this.languages, `en.${name}`, defaultValue || name);
    return _.get(this.languages, `${this.language}.${name}`, fallback);
  }
}

export const theme = {
  primary: "#00c0b7",
  secondary: "#003139",
  tertiary: "#2e2e2e",
  backgroundColor: "#e5f8f5",
};

export {baseUrl};
